import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAnalyticTotalGrowth, ISeriesLineChart } from 'src/models/general';
import { ChronologicalList } from 'src/utils/buildDataLineGroup';

interface IOverview {
  analyticTotalEngagement: ApolloQueryResult<IAnalyticTotalGrowth | undefined>;
  analyticTotalFollowers: ApolloQueryResult<IAnalyticTotalGrowth | undefined>;
  analyticTotalTalk: ApolloQueryResult<IAnalyticTotalGrowth | undefined>;
  analyticTotalReach: ApolloQueryResult<IAnalyticTotalGrowth | undefined>;
  chronologicalEngagementPeformance: ApolloQueryResult<
    Array<ISeriesLineChart> | undefined
  >;
  chronologicalFollowersGrowth: ApolloQueryResult<
    Array<ChronologicalList> | undefined
  >;
  chronologicalReachPeformance: ApolloQueryResult<
    Array<ISeriesLineChart> | undefined
  >;
  chronologicalTalkPeformance: ApolloQueryResult<
    Array<ISeriesLineChart> | undefined
  >;
}

const initialState: IOverview = {
  analyticTotalEngagement: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  analyticTotalFollowers: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  analyticTotalTalk: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  analyticTotalReach: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  chronologicalEngagementPeformance: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  chronologicalFollowersGrowth: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  chronologicalReachPeformance: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  chronologicalTalkPeformance: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  }
};

export const sosmedOverview = createSlice({
  name: 'sosmedOverview',
  initialState,
  reducers: {
    reducerUpdateAnalyticTotalEngagement: (
      state: IOverview,
      action: PayloadAction<ApolloQueryResult<IAnalyticTotalGrowth>>
    ) => {
      state.analyticTotalEngagement = action.payload;
    },
    reducerUpdateAnalyticTotalFollowers: (
      state: IOverview,
      action: PayloadAction<ApolloQueryResult<IAnalyticTotalGrowth>>
    ) => {
      state.analyticTotalFollowers = action.payload;
    },
    reducerUpdateAnalyticTotalTalk: (
      state: IOverview,
      action: PayloadAction<ApolloQueryResult<IAnalyticTotalGrowth>>
    ) => {
      state.analyticTotalTalk = action.payload;
    },
    reducerUpdateAnalyticTotalReach: (
      state: IOverview,
      action: PayloadAction<ApolloQueryResult<IAnalyticTotalGrowth>>
    ) => {
      state.analyticTotalReach = action.payload;
    },
    reducerUpdateChronologicalEngagementPeformance: (
      state: IOverview,
      action: PayloadAction<ApolloQueryResult<Array<ISeriesLineChart>>>
    ) => {
      state.chronologicalEngagementPeformance = action.payload;
    },
    reducerUpdateChronologicalFollowersGrowth: (
      state: IOverview,
      action: PayloadAction<ApolloQueryResult<Array<ChronologicalList>>>
    ) => {
      state.chronologicalFollowersGrowth = action.payload;
    },
    reducerUpdateChronologicalTalkPeformance: (
      state: IOverview,
      action: PayloadAction<ApolloQueryResult<Array<ISeriesLineChart>>>
    ) => {
      state.chronologicalTalkPeformance = action.payload;
    },
    reducerUpdateChronologicalReachPeformance: (
      state: IOverview,
      action: PayloadAction<ApolloQueryResult<Array<ISeriesLineChart>>>
    ) => {
      state.chronologicalReachPeformance = action.payload;
    },
    reducerUpdateChronologicalReachLoading: (
      state: IOverview,
      action: PayloadAction<boolean>
    ) => {
      state.chronologicalReachPeformance.loading = action.payload;
    },
    reducerUpdateAllMetricLoading: (
      state: IOverview,
      action: PayloadAction<boolean>
    ) => {
      state.analyticTotalEngagement.loading = action.payload;
      state.analyticTotalFollowers.loading = action.payload;
      state.analyticTotalReach.loading = action.payload;
      state.analyticTotalTalk.loading = action.payload;
      state.chronologicalEngagementPeformance.loading = action.payload;
      state.chronologicalFollowersGrowth.loading = action.payload;
      state.chronologicalReachPeformance.loading = action.payload;
      state.chronologicalTalkPeformance.loading = action.payload;
    }
  }
});

export const {
  reducerUpdateAnalyticTotalEngagement,
  reducerUpdateAnalyticTotalFollowers,
  reducerUpdateAnalyticTotalTalk,
  reducerUpdateAnalyticTotalReach,
  reducerUpdateChronologicalEngagementPeformance,
  reducerUpdateChronologicalFollowersGrowth,
  reducerUpdateChronologicalReachPeformance,
  reducerUpdateChronologicalTalkPeformance,
  reducerUpdateAllMetricLoading,
  reducerUpdateChronologicalReachLoading
} = sosmedOverview.actions;

export default sosmedOverview.reducer;
