import {
  ITableBordersOptions,
  ITableWidthProperties,
  Paragraph,
  TableCell,
  Document,
  Table,
  TableRow,
  BorderStyle,
  WidthType,
  TextRun,
  TableLayoutType,
  ExternalHyperlink
} from 'docx';
import { IOfflineMedia } from 'src/models/offlineMedia';
import { IOnlineMediaStreamOutputEntity } from 'src/models/onlineMedia';

const borderOptions: ITableBordersOptions = {
  top: { size: 2, style: BorderStyle.SINGLE, color: '000000' },
  bottom: { size: 2, style: BorderStyle.SINGLE, color: '000000' },
  left: { size: 2, style: BorderStyle.SINGLE, color: '000000' },
  right: { size: 2, style: BorderStyle.SINGLE, color: '000000' }
};

const columnWidths = {
  no: 5,
  mediaSource: 20,
  sentiment: 11,
  title: 34,
  url: 30
};

export const generateArticleBySentimentDoc = (
  streams: IOfflineMedia[] | IOnlineMediaStreamOutputEntity[],
  labelName: string
) => {
  const createTableCell = (
    text: string,
    borderOptions: ITableBordersOptions,
    width: ITableWidthProperties,
    isHyperlink: boolean = false
  ) => {
    return new TableCell({
      borders: borderOptions,
      width: width,
      children: [
        !isHyperlink
          ? new Paragraph(text)
          : new Paragraph({
              children: [
                new ExternalHyperlink({
                  children: [new TextRun({ text: text, style: 'Hyperlink' })],
                  link: text
                })
              ]
            })
      ]
    });
  };

  const headerRows = new TableRow({
    children: [
      createTableCell('No', borderOptions, {
        size: columnWidths.no,
        type: WidthType.PERCENTAGE
      }),
      createTableCell('Media Source', borderOptions, {
        size: columnWidths.mediaSource,
        type: WidthType.PERCENTAGE
      }),
      createTableCell('Sentiment', borderOptions, {
        size: columnWidths.sentiment,
        type: WidthType.PERCENTAGE
      }),
      createTableCell('Title', borderOptions, {
        size: columnWidths.title,
        type: WidthType.PERCENTAGE
      }),
      createTableCell('URL', borderOptions, {
        size: columnWidths.url,
        type: WidthType.PERCENTAGE
      })
    ]
  });

  const createTable = (streams: any[]) => {
    if (streams.length === 0) return;
    return new Table({
      borders: {
        insideHorizontal: { style: BorderStyle.NIL },
        insideVertical: { style: BorderStyle.NIL }
      },
      width: { size: 100, type: WidthType.PERCENTAGE },
      layout: TableLayoutType.FIXED, // Ensure table respects width settings
      rows: [
        headerRows,
        ...streams.map(
          (stream, index) =>
            new TableRow({
              cantSplit: true,
              children: [
                createTableCell(`${index + 1}`, borderOptions, {
                  size: columnWidths.no,
                  type: WidthType.PERCENTAGE
                }),
                createTableCell(stream.sourceName, borderOptions, {
                  size: columnWidths.mediaSource,
                  type: WidthType.PERCENTAGE
                }),
                createTableCell(stream.sentiment, borderOptions, {
                  size: columnWidths.sentiment,
                  type: WidthType.PERCENTAGE
                }),
                createTableCell(stream.title, borderOptions, {
                  size: columnWidths.title,
                  type: WidthType.PERCENTAGE
                }),
                createTableCell(
                  stream.link ?? stream.attachments[0].link,
                  borderOptions,
                  { size: columnWidths.url, type: WidthType.PERCENTAGE },
                  true
                )
              ]
            })
        )
      ]
    });
  };

  const getNeutralStreams = (streams: any[]) => {
    return streams.filter((stream) => stream.sentiment === 'neutral');
  };
  const getNegativeStreams = (streams: any[]) => {
    return streams.filter((stream) => stream.sentiment === 'negative');
  };
  const getPositiveStreams = (streams: any[]) => {
    return streams.filter((stream) => stream.sentiment === 'positive');
  };

  const neutralStreams = getNeutralStreams(streams);
  const negativeStreams = getNegativeStreams(streams);
  const positiveStreams = getPositiveStreams(streams);

  const doc = new Document({
    styles: {
      default: {
        document: {
          run: { size: '11pt', font: 'Calibri' },
          paragraph: { spacing: { after: 60, before: 60 } }
        }
      }
    },
    sections: [
      {
        children: [
          new Paragraph(labelName ?? ''),
          new Paragraph(`Neutral: ${neutralStreams.length}`),
          createTable(neutralStreams),
          new Paragraph({
            children: [
              new TextRun({
                text: '',
                break: 1
              })
            ]
          }),
          new Paragraph(`Positive: ${positiveStreams.length}`),
          createTable(positiveStreams),
          new Paragraph({
            children: [
              new TextRun({
                text: '',
                break: 1
              })
            ]
          }),
          new Paragraph(`Negative: ${negativeStreams.length}`),
          createTable(negativeStreams)
        ]
      }
    ]
  });

  return doc;
};
