import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IContentNumericOutputWithGrowth } from 'src/models/content';
import {
  IDataWorldCloud,
  INumericOutputWithGrowth,
  IPeakTime,
  IRawPostmadeOutputWithPagination
} from 'src/models/general';

const initDataTable: IRawPostmadeOutputWithPagination = {
  result: [],
  limit: 100,
  page: 1,
  totalPages: 1,
  totalRows: 0
};

const initNumericalOutput: INumericOutputWithGrowth = {
  contentType: '',
  growth: null,
  pastValue: null,
  recentValue: null
};

const initNumericals: IContentNumericOutputWithGrowth = {
  postMade: initNumericalOutput,
  like: initNumericalOutput,
  comment: initNumericalOutput,
  engagementRate: initNumericalOutput
};

const initWordCloud: IDataWorldCloud = {
  data: [],
  min: 0,
  max: 1
};

const initPeakTime: IPeakTime = {
  max: 0,
  min: 0,
  data: [
    {
      name: 'monday',
      data: [0]
    }
  ]
};

export interface IMetricContent {
  numericals: ApolloQueryResult<IContentNumericOutputWithGrowth>;
  overalPeformance: ApolloQueryResult<any>;
  postType: ApolloQueryResult<Array<any>>;
  postMade: ApolloQueryResult<IRawPostmadeOutputWithPagination>;
  peakTime: ApolloQueryResult<IPeakTime>;
  wordClouds: ApolloQueryResult<IDataWorldCloud>;
}

const initialState: IMetricContent = {
  numericals: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initNumericals
  },
  overalPeformance: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  peakTime: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initPeakTime
  },
  postType: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  postMade: {
    data: initDataTable,
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false
  },
  wordClouds: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initWordCloud
  }
};

export const sosmedContent = createSlice({
  name: 'sosmedContent',
  initialState,
  reducers: {
    reducerUpdateContentNumericals: (
      state: IMetricContent,
      action: PayloadAction<ApolloQueryResult<IContentNumericOutputWithGrowth>>
    ) => {
      state.numericals = action.payload;
    },
    reducerUpdateContentOveralPeformance: (
      state: IMetricContent,
      action: PayloadAction<ApolloQueryResult<any>>
    ) => {
      state.overalPeformance = action.payload;
    },
    reducerUpdateContentPeakTime: (
      state: IMetricContent,
      action: PayloadAction<ApolloQueryResult<IPeakTime>>
    ) => {
      state.peakTime = action.payload;
    },
    reducerUpdateContentPostType: (
      state: IMetricContent,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.postType = action.payload;
    },
    reducerUpdateContentTalkWordCloud: (
      state: IMetricContent,
      action: PayloadAction<ApolloQueryResult<IDataWorldCloud>>
    ) => {
      state.wordClouds = action.payload;
    },
    reducerUpdateContentPostmade: (
      state: IMetricContent,
      action: PayloadAction<ApolloQueryResult<IRawPostmadeOutputWithPagination>>
    ) => {
      state.postMade = action.payload;
    },
    reducerUpdateContentPostmadeLoading: (
      state: IMetricContent,
      action: PayloadAction<boolean>
    ) => {
      state.postMade.loading = action.payload;
    },
    reducerUpdateContentOveralPeformanceLoading: (
      state: IMetricContent,
      action: PayloadAction<boolean>
    ) => {
      state.overalPeformance.loading = action.payload;
    },
    reducerUpdateContentAllMetricLoading: (
      state: IMetricContent,
      action: PayloadAction<boolean>
    ) => {
      state.numericals.loading = action.payload;
      state.overalPeformance.loading = action.payload;
      state.peakTime.loading = action.payload;
      state.postMade.loading = action.payload;
      state.postType.loading = action.payload;
      state.wordClouds.loading = action.payload;
    },
    reducerUpdateContentAllMetricByDayLoading: (
      state: IMetricContent,
      action: PayloadAction<boolean>
    ) => {
      state.peakTime.loading = action.payload;
      state.postMade.loading = action.payload;
      state.postType.loading = action.payload;
      state.wordClouds.loading = action.payload;
    },
    reducerUpdateContentWordCloudLoading: (
      state: IMetricContent,
      action: PayloadAction<boolean>
    ) => {
      state.wordClouds.loading = action.payload;
    }
  }
});

export const {
  reducerUpdateContentAllMetricLoading,
  reducerUpdateContentAllMetricByDayLoading,
  reducerUpdateContentNumericals,
  reducerUpdateContentOveralPeformance,
  reducerUpdateContentPeakTime,
  reducerUpdateContentPostType,
  reducerUpdateContentPostmade,
  reducerUpdateContentPostmadeLoading,
  reducerUpdateContentTalkWordCloud,
  reducerUpdateContentWordCloudLoading,
  reducerUpdateContentOveralPeformanceLoading
} = sosmedContent.actions;

export default sosmedContent.reducer;
