import { IChronological, ISeriesLineChart } from 'src/models/general';

export const keyMappingChronological = function (
  chronologicals: Array<IChronological>
): Array<ISeriesLineChart> {
  return chronologicals.map((chronological) => ({
    name: chronological.name,
    data: chronological.values.map((value) => [value.timestamp, value.value])
  }));
};
