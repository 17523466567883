import {
  LazyQueryHookOptions,
  MutationHookOptions,
  OperationVariables,
  useLazyQuery,
  useMutation
} from '@apollo/client';
import { INamespace } from 'src/models/comparison';
import {
  IChronological,
  INumericOutput,
  IChronologicalOutput
} from 'src/models/general';
import { CREATE_GOOGLE_REVIEW_COMPARISON } from './graphql/googleReview/mutation';
import {
  GET_GOOGLE_REVIEW_AVG_RATING,
  GET_GOOGLE_REVIEW_CHRONOLOGICAL_RATING,
  GET_GOOGLE_REVIEW_CHRONOLOGICAL_REVIEWS,
  GET_GOOGLE_REVIEW_RATING_DISTRIBUTION,
  GET_GOOGLE_REVIEW_TOTAL_REVIEWS,
  GET_GOOGLE_REVIEW_COMPARISON_TOTAL_RATING,
  GET_GOOGLE_REVIEW_COMPARISON_TOTAL_REVIEWS,
  GET_GOOGLE_REVIEW_COMPARISON_CHRONOLOGICAL_REVIEWS,
  GET_GOOGLE_REVIEW_COMPARISON_CHRONOLOGICAL_RATINGS,
  GET_GOOGLE_REVIEW_COMPARISON_LIST
} from './graphql/googleReview/query';

export interface IGetTotalReviewsResponse {
  googleReview_getGoogleReview: INumericOutput;
}

export interface IGetAverageRatingResponse {
  googleReview_getGoogleRating: INumericOutput;
}

export interface IGetChronologicalRatingResponse {
  googleReview_getChronologicalRating: IChronologicalOutput[];
}

export interface IGetChronologicalReviewsResponse {
  googleReview_getChronologicalReviews: IChronologicalOutput[];
}

interface IRatingDistribution {
  timeStamp: string;
  oneStar: number;
  twoStar: number;
  threeStar: number;
  fourStar: number;
  fiveStar: number;
}

export interface IGetRatingDistributionResponse {
  googleReview_getRatingDistribution: IRatingDistribution[];
}

// Comparison response interfaces
export interface IComparisonTotalRatingResponse {
  googleComparison_getTotalRating: INumericOutput[];
}

export interface IComparisonTotalReviewsResponse {
  googleComparison_getTotalReviews: INumericOutput[];
}

export interface IComparisonChronologicalReviewsResponse {
  googleComparison_getChronologicalReviews: IChronological[];
}

export interface IComparisonChronologicalRatingsResponse {
  googleComparison_getChronologicalRatings: IChronological[];
}

export interface ICreateGoogleComparison {
  googleComparison_addComparisonGoogle: INamespace;
}

export interface IGetGoogleComparisonListResponse {
  googleComparison_getComparisonNamespaceList: INamespace[];
}

const useGoogleReview = () => {
  const defaultOptions: LazyQueryHookOptions = {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
  };

  const useGetTotalReviews = (
    options?: LazyQueryHookOptions<IGetTotalReviewsResponse, OperationVariables>
  ) => {
    return useLazyQuery<IGetTotalReviewsResponse>(
      GET_GOOGLE_REVIEW_TOTAL_REVIEWS,
      {
        ...defaultOptions,
        ...options
      }
    );
  };

  const useGetAverageRating = (
    options?: LazyQueryHookOptions<
      IGetAverageRatingResponse,
      OperationVariables
    >
  ) => {
    return useLazyQuery<IGetAverageRatingResponse>(
      GET_GOOGLE_REVIEW_AVG_RATING,
      {
        ...defaultOptions,
        ...options
      }
    );
  };

  const useGetChronologicalRating = (
    options?: LazyQueryHookOptions<
      IGetChronologicalRatingResponse,
      OperationVariables
    >
  ) => {
    return useLazyQuery<IGetChronologicalRatingResponse>(
      GET_GOOGLE_REVIEW_CHRONOLOGICAL_RATING,
      { ...defaultOptions, ...options }
    );
  };

  const useGetChronologicalReviews = (
    options?: LazyQueryHookOptions<
      IGetChronologicalReviewsResponse,
      OperationVariables
    >
  ) => {
    return useLazyQuery<IGetChronologicalReviewsResponse>(
      GET_GOOGLE_REVIEW_CHRONOLOGICAL_REVIEWS,
      { ...defaultOptions, ...options }
    );
  };

  const useGetRatingDistribution = (
    options?: LazyQueryHookOptions<
      IGetRatingDistributionResponse,
      OperationVariables
    >
  ) => {
    return useLazyQuery<IGetRatingDistributionResponse>(
      GET_GOOGLE_REVIEW_RATING_DISTRIBUTION,
      { ...defaultOptions, ...options }
    );
  };

  // Comparison hooks
  const useGetComparisonTotalRating = (
    options?: LazyQueryHookOptions<
      IComparisonTotalRatingResponse,
      OperationVariables
    >
  ) => {
    return useLazyQuery<IComparisonTotalRatingResponse>(
      GET_GOOGLE_REVIEW_COMPARISON_TOTAL_RATING,
      { ...defaultOptions, ...options }
    );
  };

  const useGetComparisonTotalReviews = (
    options?: LazyQueryHookOptions<
      IComparisonTotalReviewsResponse,
      OperationVariables
    >
  ) => {
    return useLazyQuery<IComparisonTotalReviewsResponse>(
      GET_GOOGLE_REVIEW_COMPARISON_TOTAL_REVIEWS,
      { ...defaultOptions, ...options }
    );
  };

  const useGetComparisonChronologicalReviews = (
    options?: LazyQueryHookOptions<
      IComparisonChronologicalReviewsResponse,
      OperationVariables
    >
  ) => {
    return useLazyQuery<IComparisonChronologicalReviewsResponse>(
      GET_GOOGLE_REVIEW_COMPARISON_CHRONOLOGICAL_REVIEWS,
      { ...defaultOptions, ...options }
    );
  };

  const useGetComparisonChronologicalRatings = (
    options?: LazyQueryHookOptions<
      IComparisonChronologicalRatingsResponse,
      OperationVariables
    >
  ) => {
    return useLazyQuery<IComparisonChronologicalRatingsResponse>(
      GET_GOOGLE_REVIEW_COMPARISON_CHRONOLOGICAL_RATINGS,
      { ...defaultOptions, ...options }
    );
  };

  const useCreateGoogleComparison = (options?: MutationHookOptions) => {
    return useMutation<ICreateGoogleComparison>(
      CREATE_GOOGLE_REVIEW_COMPARISON,
      options
    );
  };

  const useGetGoogleComparisonList = (
    options?: LazyQueryHookOptions<
      IGetGoogleComparisonListResponse,
      OperationVariables
    >
  ) => {
    return useLazyQuery<IGetGoogleComparisonListResponse>(
      GET_GOOGLE_REVIEW_COMPARISON_LIST,
      { nextFetchPolicy: 'cache-first', ...defaultOptions, ...options }
    );
  };

  return {
    useGetTotalReviews,
    useGetAverageRating,
    useGetChronologicalRating,
    useGetChronologicalReviews,
    useGetRatingDistribution,
    useGetComparisonTotalRating,
    useGetComparisonTotalReviews,
    useGetComparisonChronologicalReviews,
    useGetComparisonChronologicalRatings,
    useCreateGoogleComparison,
    useGetGoogleComparisonList
  };
};

export default useGoogleReview;
