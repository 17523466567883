import React, { FC } from 'react';
import { TSocialMedia } from 'src/models/general';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import TiktokIcon from '../TikTokIcon/TikTokIcon';
import { LinkedIn } from '@mui/icons-material';
import GoogleIcon from '../GoogleIcon';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

interface Props {
  socialMedia?: TSocialMedia;
  sx?: SxProps<Theme>;
}

const SocialMediaIcon: FC<Props> = ({ socialMedia, sx }) => {
  switch (socialMedia) {
    case 'twitter':
      return <TwitterIcon fontSize="small" color="primary" sx={sx} />;
    case 'instagram':
      return <InstagramIcon fontSize="small" color="primary" sx={sx} />;
    case 'youtube':
      return <YouTubeIcon fontSize="small" color="primary" sx={sx} />;
    case 'facebook':
      return <FacebookIcon fontSize="small" color="primary" sx={sx} />;
    case 'tiktok':
      return <TiktokIcon fontSize="small" color="primary" sx={sx} />;
    case 'linkedin':
      return <LinkedIn fontSize="small" color="primary" sx={sx} />;
    case 'googleReview':
      return <GoogleIcon fontSize="small" color="primary" sx={sx} />;
    case 'telegram':
      return <TelegramIcon fontSize="small" color="primary" sx={sx} />;
    case 'whatsapp':
      return <WhatsAppIcon fontSize="small" color="primary" sx={sx} />;
    default:
      return <TwitterIcon fontSize="small" color="primary" sx={sx} />;
  }
};

export default SocialMediaIcon;
