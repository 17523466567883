import { IOfflineMedia } from 'src/models/offlineMedia';
import { IOnlineMediaStreamOutputEntity } from 'src/models/onlineMedia';
import { Document, LevelFormat, Paragraph, TextRun } from 'docx';
export function generateWhatsappReportDoc(
  streams: IOfflineMedia[] | IOnlineMediaStreamOutputEntity[]
) {
  const doc = new Document({
    styles: {
      default: {
        document: {
          run: { size: '11pt', font: 'Calibri' },
          paragraph: { spacing: { after: 60, before: 60 } }
        }
      }
    },
    numbering: {
      config: [
        {
          reference: 'default-numbering',
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: '%1'
            }
          ]
        }
      ]
    },
    sections: [
      {
        children: streams.map(
          (stream: IOfflineMedia | IOnlineMediaStreamOutputEntity) =>
            new Paragraph({
              children: [
                new TextRun(`${stream.title} (${stream.sourceName})`),
                new TextRun({ text: '', break: 1 }),
                new TextRun(
                  'link' in stream
                    ? stream.link
                    : stream.attachments[0].link ?? 'Link not found'
                ),
                new TextRun({ text: '', break: 1 })
              ],
              numbering: { reference: 'default-numbering', level: 0 }
            })
        )
      }
    ]
  });

  return doc;
}
