import { gql } from 'graphql-tag';

export const CREATE_GOOGLE_REVIEW_COMPARISON = gql`
  mutation googleComparison_addComparisonGoogle(
    $projectId: Id!
    $comparisonName: String!
    $comparisonGroups: [ComparisonGroupInput!]!
    $sourceType: SourceType!
  ) {
    googleComparison_addComparisonGoogle(
      projectId: $projectId
      comparisonName: $comparisonName
      comparisonGroups: $comparisonGroups
      sourceType: $sourceType
    ) {
      id
      namespace
      displayName
      createdAt
      groups {
        id
        name
        displayName
        createdAt
        objectGroup {
          id
          name
          displayName
          createdAt
          objectList {
            id
            socialMedia
            streamType
            content
            extraContent
            objectName
            isHashtag
            openApiId
            createdAt
            displayPicture
            includes
            excludes
            languages
          }
        }
        objectList {
          id
          socialMedia
          streamType
          content
          extraContent
          objectName
          isHashtag
          openApiId
          createdAt
          displayPicture
          includes
          excludes
          languages
        }
        labelList {
          id
          name
          displayName
          colorType
        }
      }
      colors
    }
  }
`;
