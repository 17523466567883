import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  IHeadCellAdvanceMetrics,
  initHeadCells,
  reducerUpdateAdvanceMetric,
  reducerUpdateAllLoadingComparison,
  reducerUpdateBrandPosition,
  reducerUpdateComparisonEntity,
  reducerUpdateComparisonList,
  reducerUpdateDayToDayEngagement,
  reducerUpdateDayToDayFollowersGrowth,
  reducerUpdateDayToDayTalk,
  reducerUpdateListOfPostMade,
  reducerUpdateLoadingListOfPostMade,
  reducerUpdatePeakTime,
  reducerUpdateSentimentAnalysis,
  reducerUpdateShareOfVoice
} from 'src/features/sosmedComparison';
import {
  ADD_COMPARISON,
  DELETE_COMPARISON,
  SET_ADVANCE_METRIC_SELECTION,
  SET_DEFAULT,
  EDIT_COMPARISON
} from './graphql/comparison/mutation';
import { useRef, useState } from 'react';
import {
  GET_ADVANCE_METRIC,
  GET_BRAND_PEAK_TIME,
  GET_BRAND_POPULARITY,
  GET_BRAND_POSITIONING,
  GET_BRAND_SENTIMENT,
  GET_CHRONOLOGICAL_AUDIENCE_BY_BRAND,
  GET_CHRONOLOGICAL_ENGAGEMENT_BY_BRAND,
  GET_CHRONOLOGICAL_TALK_BY_BRAND,
  GET_COMPARISON_ENTITY,
  GET_NAMESPACE_LIST,
  GET_TOP_POSTMADE_PAGINATION_V2
} from './graphql/comparison/query';
import uuidNameSpace from 'uuid/v5';
import getNS from 'uuid/v4';
import {
  IBrandPositioning,
  IComparisonEntity,
  IGroupSentiment,
  INamespace,
  IPeakTimeGroup,
  IRawAdvanceMetric,
  IShareOfVoice
} from 'src/models/comparison';
import {
  IAdvanceSearchSocialMedia,
  IChronological,
  IRawPostmadeOutputWithPagination
} from 'src/models/general';
import { generateGroupSentiment } from 'src/utils/generateChronologicalGroup';

interface IGetListComparisonResponse {
  comparison_getComparisonNamespaceList: Array<INamespace>;
}

interface IGetComparisonEntityResponse {
  comparison_getComparisonEntity: IComparisonEntity;
}

interface IGetBrandPositioningResponse {
  comparison_getBrandPositioning: Array<IBrandPositioning>;
}

interface IGetShareOfVoiceResponse {
  comparison_getBrandPopularity: Array<IShareOfVoice>;
}

interface IGetSentimentAnalysisResponse {
  comparison_getBrandSentiment: Array<IGroupSentiment>;
}

interface IGetChronologicalEngagementResponse {
  comparison_getChronologicalEngagementByBrand: Array<IChronological>;
}

interface IGetChronologicalFollowersGrowthResponse {
  comparison_getChronologicalAudienceByBrand: Array<IChronological>;
}

interface IGetDayToDayTalkResponse {
  comparison_getChronologicalTalkByBrand: Array<IChronological>;
}

interface IGetPeakTimeResponse {
  comparison_getBrandPeakTime: IPeakTimeGroup;
}

interface IGetTopPostMadeResponse {
  comparison_getTopPostMadeWithPaginationV2: IRawPostmadeOutputWithPagination;
}

interface IGetAdvanceMetricResponse {
  comparison_getAdvanceMetric: Array<IRawAdvanceMetric>;
}

const RequestNameSpace = getNS();

const useComparison = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const [headCellsAdvanceMetrics, setHeadCellAdvanceMetrics] =
    useState<Array<IHeadCellAdvanceMetrics>>(initHeadCells);
  const {
    advanceMetric: currentAdvanceMetric,
    comparisonList,
    dayToDayEngagement,
    dayToDayFollowersGrowth,
    dayToDayTalk
  } = useAppSelector((state) => state.storeComparison);

  const fetchListComparison = async function (projectId: string) {
    return await client
      .query<IGetListComparisonResponse>({
        query: GET_NAMESPACE_LIST,
        variables: {
          projectId
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.comparison_getComparisonNamespaceList
        };
      });
  };

  const getListComparison = async function (projectId: string) {
    const response = await fetchListComparison(projectId);
    dispatch(reducerUpdateComparisonList(response));
  };

  const fetchComparisonEntity = async function (comparisonId: string) {
    return await client
      .query<IGetComparisonEntityResponse>({
        query: GET_COMPARISON_ENTITY,
        variables: {
          comparisonId
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.comparison_getComparisonEntity
        };
      });
  };

  const getComparisonEntity = async function (comparisonId: string) {
    const response = await fetchComparisonEntity(comparisonId);
    dispatch(reducerUpdateComparisonEntity(response));
  };

  const lastPromiseBrand: any = useRef();
  const lastPromiseSov: any = useRef();
  const lastPromiseEngagement: any = useRef();
  const lastPromiseSentiment: any = useRef();
  const lastPromiseFollower: any = useRef();
  const lastPromiseTalk: any = useRef();
  const lastPromisePeaktime: any = useRef();
  const lastPromisePostMade: any = useRef();
  const lastPromiseAdvance: any = useRef();

  const fetchBrandPositioning = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const resBrandPositioning =
      await client.query<IGetBrandPositioningResponse>({
        query: GET_BRAND_POSITIONING,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_BRAND_POSITIONING',
            RequestNameSpace
          )
        }
      });
    const generateData =
      resBrandPositioning.data.comparison_getBrandPositioning.map(
        (position) => ({
          name: position.name,
          data: [[position?.positivity, position?.popularity]]
        })
      );
    return { ...resBrandPositioning, data: generateData };
  };

  const fetchShareOfVoice = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const resShareOfVoice = await client.query<IGetShareOfVoiceResponse>({
      query: GET_BRAND_POPULARITY,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_BRAND_POPULARITY',
          RequestNameSpace
        )
      }
    });
    return {
      ...resShareOfVoice,
      data: resShareOfVoice.data.comparison_getBrandPopularity
    };
  };

  const fetchSentimentAnalysis = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IGetSentimentAnalysisResponse>({
      query: GET_BRAND_SENTIMENT,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace('GET_BRAND_SENTIMENT', RequestNameSpace)
      }
    });
    const generateData = generateGroupSentiment(
      response.data?.comparison_getBrandSentiment,
      ['positive', 'negative', 'neutral']
    );
    return { ...response, data: generateData };
  };

  const keyMappingChronological = function (
    chronologicals: Array<IChronological>
  ) {
    return chronologicals.map((chronological) => ({
      name: chronological.name,
      data: chronological.values.map((value) => [value.timestamp, value.value])
    }));
  };

  const fetchDayToDayEngagement = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IGetChronologicalEngagementResponse>({
      query: GET_CHRONOLOGICAL_ENGAGEMENT_BY_BRAND,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_CHRONOLOGICAL_ENGAGEMENT_BY_BRAND',
          RequestNameSpace
        )
      }
    });
    const generateData = keyMappingChronological(
      response.data?.comparison_getChronologicalEngagementByBrand
    );
    return { ...response, data: generateData };
  };

  const fetchDayToDayFollowersGrowth = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response =
      await client.query<IGetChronologicalFollowersGrowthResponse>({
        query: GET_CHRONOLOGICAL_AUDIENCE_BY_BRAND,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CHRONOLOGICAL_AUDIENCE_BY_BRAND',
            RequestNameSpace
          )
        }
      });
    const generateData = keyMappingChronological(
      response.data?.comparison_getChronologicalAudienceByBrand
    );
    return { ...response, data: generateData };
  };

  const fetchDayToDayTalk = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IGetDayToDayTalkResponse>({
      query: GET_CHRONOLOGICAL_TALK_BY_BRAND,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_CHRONOLOGICAL_TALK_BY_BRAND',
          RequestNameSpace
        )
      }
    });
    const generateData = keyMappingChronological(
      response.data?.comparison_getChronologicalTalkByBrand
    );
    return { ...response, data: generateData };
  };

  const keyMappingBrandPeaktime = function (datas) {
    return datas.map((data) => ({
      name: data.name,
      data: data.values.map((value, index) => [index, value])
    }));
  };

  const fetchPeakTime = async function (parameter: IAdvanceSearchSocialMedia) {
    const response = await client.query<IGetPeakTimeResponse>({
      query: GET_BRAND_PEAK_TIME,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace('GET_BRAND_PEAK_TIME', RequestNameSpace)
      }
    });
    const generateData = keyMappingBrandPeaktime(
      response.data?.comparison_getBrandPeakTime
    );
    return { ...response, data: generateData };
  };

  const fetchListOfPostMade = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IGetTopPostMadeResponse>({
      query: GET_TOP_POSTMADE_PAGINATION_V2,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_TOP_POSTMADE_PAGINATION_V2',
          RequestNameSpace
        )
      }
    });
    return {
      ...response,
      data: response.data?.comparison_getTopPostMadeWithPaginationV2
    };
  };

  const fetchAllListOfPostMade = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IGetTopPostMadeResponse>({
      query: GET_TOP_POSTMADE_PAGINATION_V2,
      variables: parameter
    });
    return {
      data: response.data?.comparison_getTopPostMadeWithPaginationV2
    };
  };

  const fetchAdvanceMetric = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IGetAdvanceMetricResponse>({
      query: GET_ADVANCE_METRIC,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace('GET_ADVANCE_METRIC', RequestNameSpace)
      }
    });

    setHeadCellAdvanceMetrics((prevCells: Array<IHeadCellAdvanceMetrics>) =>
      prevCells.map((col) => {
        const column =
          response.data?.comparison_getAdvanceMetric[0]?.values.find(
            (val) => val.computationName === col.id
          );
        return { ...col, active: column?.isActive };
      })
    );
    const mappingMetric = response.data?.comparison_getAdvanceMetric.map(
      (metric) => {
        const objectMetric = { group: metric.name };
        headCellsAdvanceMetrics.forEach((headMetric) => {
          metric.values.forEach((val) => {
            if (headMetric.id === val.computationName && val.isActive) {
              Object.assign(objectMetric, {
                [val.computationName]: val.value
              });
            }
          });
        });
        return objectMetric;
      }
    );
    return { ...response, data: mappingMetric };
  };

  const getBrandPositioning = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchBrandPositioning(parameter);
    lastPromiseBrand.current = response;
    if (response === lastPromiseBrand.current) {
      response.then((response) => {
        dispatch(reducerUpdateBrandPosition(response));
      });
    }
  };

  const getShareOfVoice = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchShareOfVoice(parameter);
    lastPromiseSov.current = response;
    if (response === lastPromiseSov.current) {
      response.then((response) => {
        dispatch(reducerUpdateShareOfVoice(response));
      });
    }
  };

  const getSentimentAnalysis = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchSentimentAnalysis(parameter);
    lastPromiseSentiment.current = response;
    if (response === lastPromiseSentiment.current) {
      response.then((response) => {
        dispatch(reducerUpdateSentimentAnalysis(response));
      });
    }
  };

  const getDayToDayEngagement = function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    dispatch(
      reducerUpdateDayToDayEngagement({ ...dayToDayEngagement, loading: true })
    );
    const response = fetchDayToDayEngagement(parameter);
    lastPromiseEngagement.current = response;
    if (response === lastPromiseEngagement.current) {
      response.then((response) => {
        dispatch(reducerUpdateDayToDayEngagement(response));
      });
    }
  };

  const getDayToDayFollowersGrowth = function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    dispatch(
      reducerUpdateDayToDayFollowersGrowth({
        ...dayToDayFollowersGrowth,
        loading: true
      })
    );
    const response = fetchDayToDayFollowersGrowth(parameter);
    lastPromiseFollower.current = response;
    if (response === lastPromiseFollower.current) {
      response.then((response) => {
        dispatch(reducerUpdateDayToDayFollowersGrowth(response));
      });
    }
  };

  const getDayToDayTalk = function (parameter: IAdvanceSearchSocialMedia) {
    dispatch(reducerUpdateDayToDayTalk({ ...dayToDayTalk, loading: true }));
    const response = fetchDayToDayTalk(parameter);
    lastPromiseTalk.current = response;
    if (response === lastPromiseTalk.current) {
      response.then((response) => {
        dispatch(reducerUpdateDayToDayTalk(response));
      });
    }
  };

  const getPeakTime = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchPeakTime(parameter);
    lastPromisePeaktime.current = response;
    if (response === lastPromisePeaktime.current) {
      response.then((response) => {
        dispatch(reducerUpdatePeakTime(response));
      });
    }
  };

  const getListOfPostMade = function (parameter: IAdvanceSearchSocialMedia) {
    dispatch(reducerUpdateLoadingListOfPostMade(true));
    const response = fetchListOfPostMade(parameter);
    lastPromisePostMade.current = response;
    if (response === lastPromisePostMade.current) {
      response.then((response) => {
        dispatch(reducerUpdateListOfPostMade(response));
      });
    }
  };

  const getAdvanceMetric = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchAdvanceMetric(parameter);
    lastPromiseAdvance.current = response;
    if (response === lastPromiseAdvance.current) {
      response.then((response) => {
        dispatch(reducerUpdateAdvanceMetric(response));
      });
    }
  };

  const getAllMetricCompatisonSosmed = (
    parameter: IAdvanceSearchSocialMedia
  ) => {
    dispatch(reducerUpdateAllLoadingComparison(true));
    getBrandPositioning(parameter);
    getShareOfVoice(parameter);
    getSentimentAnalysis(parameter);
    // getDayToDayFollowersGrowth(parameter);
    getPeakTime(parameter);
    // getDayToDayTalk(parameter);
    getListOfPostMade(parameter);
    getAdvanceMetric(parameter);
  };

  const cekSourceType = (groups): 'object' | 'group' | 'label' => {
    if (groups[0].objectGroup?.length > 0) return 'group';
    if (groups[0].objectList?.length > 0) return 'object';
    if (groups[0].labelList?.length > 0) return 'label';
  };

  const [addComparison, resAddComparison] = useMutation(ADD_COMPARISON, {
    onCompleted: ({ comparison_addComparison }) => {
      const { id, namespace, displayName, createdAt, groups, colors } =
        comparison_addComparison;
      const newComparison: INamespace = {
        id,
        namespace,
        displayName,
        createdAt,
        isDefault: false,
        colors,
        sourceType: cekSourceType(groups)
      };
      dispatch(
        reducerUpdateComparisonList({
          ...comparisonList,
          data: [...comparisonList.data, newComparison]
        })
      );
    }
  });
  const [editComparison, resEditComparison] = useMutation(EDIT_COMPARISON);

  const [deleteComparison, resDeleteComparison] = useMutation(
    DELETE_COMPARISON,
    {
      onError(err) {
        console.log(err);
      }
    }
  );

  const [setDefault, responseSetDefault] = useMutation(SET_DEFAULT, {
    onError(err) {
      console.log(err);
    }
  });

  const [setComparisonTableColumn, responseComparisonTableColumn] = useMutation(
    SET_ADVANCE_METRIC_SELECTION,
    {
      onCompleted: ({ comparison_updateAdvanceMetricSelection }) => {
        setHeadCellAdvanceMetrics((prevCells: Array<IHeadCellAdvanceMetrics>) =>
          prevCells.map((col) => {
            const column =
              comparison_updateAdvanceMetricSelection[0].values.find(
                (val) => val.computationName === col.id
              );
            return { ...col, active: column.isActive };
          })
        );
        const mappingMetric = comparison_updateAdvanceMetricSelection.map(
          (metric: any) => {
            const objectMetric = { group: metric.name };
            headCellsAdvanceMetrics.forEach((headMetric) => {
              metric.values.forEach((val) => {
                if (headMetric.id === val.computationName && val.isActive) {
                  Object.assign(objectMetric, {
                    [val.computationName]: val.value
                  });
                }
              });
            });
            return objectMetric;
          }
        );
        dispatch(
          reducerUpdateAdvanceMetric({
            ...currentAdvanceMetric,
            data: mappingMetric
          })
        );
      },
      context: {
        requestTrackerId: uuidNameSpace(
          'SET_ADVANCE_METRIC_SELECTION',
          RequestNameSpace
        )
      },
      onError(err) {
        console.log(err);
      }
    }
  );

  return {
    getListComparison,
    addComparison,
    resAddComparison,
    deleteComparison,
    resDeleteComparison,
    getAllMetricCompatisonSosmed,
    getListOfPostMade,
    fetchAllListOfPostMade,
    headCellsAdvanceMetrics,
    setHeadCellAdvanceMetrics,
    setComparisonTableColumn,
    responseComparisonTableColumn,
    setDefault,
    responseSetDefault,
    getComparisonEntity,
    editComparison,
    resEditComparison,
    getDayToDayEngagement,
    getDayToDayFollowersGrowth,
    getDayToDayTalk
  };
};

export default useComparison;
