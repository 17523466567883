import { SxProps, Theme } from '@mui/system';
import { TSocialMedia } from 'src/models/general';

import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TiktokIcon from '../TikTokIcon/TikTokIcon';
import LinkedinIcon from '@mui/icons-material/LinkedIn';
import GoogleIcon from '../GoogleIcon';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Google } from '@mui/icons-material';

type TProps = {
  socialMedia: TSocialMedia;
  fontSize?: 'small' | 'inherit' | 'large' | 'medium';
  color?:
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  sx?: SxProps<Theme>;
  isChecked?: boolean;
  isSolidColor?: boolean;
};

const GetSocialMediaIcon = ({
  socialMedia,
  color,
  fontSize = 'small',
  sx,
  isChecked,
  isSolidColor = false
}: TProps) => {
  switch (socialMedia) {
    case 'twitter':
      return (
        <TwitterIcon fontSize={fontSize} color={color} sx={sx ? sx : null} />
      );
    case 'instagram':
      return (
        <InstagramIcon fontSize={fontSize} color={color} sx={sx ? sx : null} />
      );
    case 'youtube':
      return (
        <YouTubeIcon fontSize={fontSize} color={color} sx={sx ? sx : null} />
      );
    case 'facebook':
      return (
        <FacebookIcon fontSize={fontSize} color={color} sx={sx ? sx : null} />
      );
    case 'tiktok':
      return (
        <TiktokIcon fontSize={fontSize} color={color} sx={sx ? sx : null} />
      );
    case 'linkedin':
      return (
        <LinkedinIcon fontSize={fontSize} color={color} sx={sx ? sx : null} />
      );
    case 'googleReview':
      if (isSolidColor)
        return <Google fontSize={fontSize} color={color} sx={sx ? sx : null} />;
      return (
        <GoogleIcon fontSize={fontSize} color={color} sx={sx ? sx : null} />
      );
    case 'telegram':
      return (
        <TelegramIcon fontSize={fontSize} color={color} sx={sx ? sx : null} />
      );
    case 'whatsapp':
      return (
        <WhatsAppIcon fontSize={fontSize} color={color} sx={sx ? sx : null} />
      );
    default:
      return (
        <TwitterIcon fontSize={fontSize} color={color} sx={sx ? sx : null} />
      );
  }
};

export default GetSocialMediaIcon;
