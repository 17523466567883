import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAdvanceSearchSocialMedia,
  ILabel,
  TContentType,
  TPostOwnership,
  TSentiment,
  TValidation
} from 'src/models/general';

export const initialState: IAdvanceSearchSocialMedia = {
  sentiment: [],
  postOwnership: [],
  contentTypeList: [],
  validationList: [],
  labelList: [],
  excludeLabelList: [],
  includeKeywords: [],
  excludeKeywords: [],
  fromUsername: '',
  replyToOriginalId: [],
  showEmptyLabel: false,
  sentimentConfidenceLevelMin: 0,
  sentimentConfidenceLevelMax: 1,
  streamsCommentingToPost: {
    result: [],
    totalRows: 0,
    totalPages: 0,
    page: 1,
    limit: 20
  },
  selectedOriginalToPost: [],
  excludeRt: false,
  queryStringBooleanOperator: 'and',
  languageList: [],
  userRating: []
};

export const advanceSearchSocialMedia = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    reducerUpdateAdvanceSearchSentiment: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<Array<TSentiment>>
    ) => {
      state.sentiment = action.payload;
    },
    reducerUpdateAdvanceSearchPostOwnership: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<Array<TPostOwnership>>
    ) => {
      state.postOwnership = action.payload;
    },
    reducerUpdateAdvanceSearchContentTypeList: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<Array<TContentType>>
    ) => {
      state.contentTypeList = action.payload;
    },
    reducerUpdateAdvanceSearchValidationList: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<Array<TValidation>>
    ) => {
      state.validationList = action.payload;
    },
    reducerUpdateAdvanceSearchLabelList: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<Array<string>>
    ) => {
      state.labelList = action.payload;
    },
    reducerUpdateAdvanceSearchIncludeKeywords: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<Array<string>>
    ) => {
      state.includeKeywords = action.payload;
    },
    reducerUpdateAdvanceSearchExcludeKeywords: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<Array<string>>
    ) => {
      state.excludeKeywords = action.payload;
    },
    reducerUpdateAdvanceSearchFromUsername: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<string>
    ) => {
      state.fromUsername = action.payload;
    },
    reducerUpdateAdvanceSearchReplyToOriginalId: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<Array<string>>
    ) => {
      state.replyToOriginalId = action.payload;
    },
    reducerUpdateAdvanceSearchShowEmptyLabel: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<boolean>
    ) => {
      state.showEmptyLabel = action.payload;
    },
    reducerUpdateAdvanceSearchSentimentConfidenceLevelMin: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<number>
    ) => {
      state.sentimentConfidenceLevelMin = action.payload;
    },
    reducerUpdateAdvanceSearchSentimentConfidenceLevelMax: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<number>
    ) => {
      state.sentimentConfidenceLevelMax = action.payload;
    },
    reducerUpdateExcludeRt: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<boolean>
    ) => {
      state.excludeRt = action.payload;
    },
    reducerUpdateAdvanceSearch: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<IAdvanceSearchSocialMedia>
    ) => {
      state.includeKeywords = action.payload.includeKeywords;
      state.excludeKeywords = action.payload.excludeKeywords;
      state.fromUsername = action.payload.fromUsername;
      state.sentiment = action.payload.sentiment;
      state.postOwnership = action.payload.postOwnership;
      state.validationList = action.payload.validationList;
      state.contentTypeList = action.payload.contentTypeList;
      state.labelList = action.payload.labelList;
      state.excludeLabelList = action.payload.excludeLabelList;
      state.sentimentConfidenceLevelMin =
        action.payload.sentimentConfidenceLevelMin;
      state.sentimentConfidenceLevelMax =
        action.payload.sentimentConfidenceLevelMax;
      state.showEmptyLabel = action.payload.showEmptyLabel;
      state.replyToOriginalId = action.payload.replyToOriginalId;
      state.streamsCommentingToPost = action.payload.streamsCommentingToPost;
      state.selectedOriginalToPost = action.payload.selectedOriginalToPost;
      state.searchOriginalToPost = action.payload.searchOriginalToPost;
      state.excludeRt = action.payload.excludeRt;
      state.queryStringBooleanOperator =
        action.payload.queryStringBooleanOperator;
      state.languageList = action.payload.languageList;
      state.userRating = action.payload.userRating;
    },
    reducerUpdateAdvanceClear: (
      state: IAdvanceSearchSocialMedia,
      action: PayloadAction<IAdvanceSearchSocialMedia>
    ) => {
      state.includeKeywords = action.payload.includeKeywords;
      state.excludeKeywords = action.payload.excludeKeywords;
      state.fromUsername = action.payload.fromUsername;
      state.sentiment = action.payload.sentiment;
      state.postOwnership = action.payload.postOwnership;
      state.validationList = action.payload.validationList;
      state.contentTypeList = action.payload.contentTypeList;
      state.labelList = action.payload.labelList;
      state.excludeLabelList = action.payload.excludeLabelList;
      state.sentimentConfidenceLevelMin =
        action.payload.sentimentConfidenceLevelMin;
      state.sentimentConfidenceLevelMax =
        action.payload.sentimentConfidenceLevelMax;
      state.showEmptyLabel = action.payload.showEmptyLabel;
      state.replyToOriginalId = action.payload.replyToOriginalId;
      state.streamsCommentingToPost = action.payload.streamsCommentingToPost;
      state.selectedOriginalToPost = action.payload.selectedOriginalToPost;
      state.searchOriginalToPost = action.payload.searchOriginalToPost;
      state.excludeRt = action.payload.excludeRt;
      state.queryStringBooleanOperator =
        action.payload.queryStringBooleanOperator;
      state.languageList = action.payload.languageList;
      state.userRating = action.payload.userRating;
    }
  }
});

export const {
  reducerUpdateAdvanceSearch,
  reducerUpdateAdvanceSearchSentiment,
  reducerUpdateAdvanceSearchContentTypeList,
  reducerUpdateAdvanceSearchExcludeKeywords,
  reducerUpdateAdvanceSearchFromUsername,
  reducerUpdateAdvanceSearchIncludeKeywords,
  reducerUpdateAdvanceSearchLabelList,
  reducerUpdateAdvanceSearchPostOwnership,
  reducerUpdateAdvanceSearchReplyToOriginalId,
  reducerUpdateAdvanceSearchSentimentConfidenceLevelMax,
  reducerUpdateAdvanceSearchSentimentConfidenceLevelMin,
  reducerUpdateAdvanceSearchShowEmptyLabel,
  reducerUpdateAdvanceSearchValidationList,
  reducerUpdateExcludeRt,
  reducerUpdateAdvanceClear
} = advanceSearchSocialMedia.actions;

export default advanceSearchSocialMedia.reducer;
