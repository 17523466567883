import {
  LazyQueryHookOptions,
  useApolloClient,
  useLazyQuery
} from '@apollo/react-hooks';
import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  reducerUpdateConversationAllMetricLoading,
  reducerUpdateConversationAllMetricLoadingPerDay,
  reducerUpdateConversationByMedia,
  reducerUpdateConversationDayToDayConversation,
  reducerUpdateConversationDayToDayLoading,
  reducerUpdateConversationListOfTalk,
  reducerUpdateConversationListOfTalkLoading,
  reducerUpdateConversationNumericals,
  reducerUpdateConversationPeakTime,
  reducerUpdateConversationSentimentAnalysis,
  reducerUpdateConversationTalkerByGender,
  reducerUpdateConversationTalkerByInterest,
  reducerUpdateConversationTalkerByLocations,
  reducerUpdateConversationTalkerBySentiment,
  reducerUpdateConversationTopEngagement,
  reducerUpdateConversationTopInfluencer,
  reducerUpdateConversationTopTalker,
  reducerUpdateConversationWordClouds
} from 'src/features/sosmedConversation';
import {
  IAdvanceSearchSocialMedia,
  IAnalyticTotalGrowth,
  INumericOutput,
  IRawPostmadeOutputWithPagination,
  IUserEngagement,
  IValuePieChart,
  IWordCloud
} from 'src/models/general';
import {
  buildDataLineGroup,
  ChronologicalList
} from 'src/utils/buildDataLineGroup';
import sortingDayPeakTime from 'src/utils/sortingDayPeakTime';
import {
  GET_CONVERSATION_AUDIENCE_ENGAGEMENT,
  GET_CONVERSATION_CONVERSATION_GROUP_TOTAL,
  GET_CONVERSATION_FOLLOWERS_BY_GENDER,
  GET_CONVERSATION_GROUP_CHRONOLOGICAL_CONVERSATION,
  GET_CONVERSATION_SENTIMENT_ANALYSIS,
  GET_CONVERSATION_TALKER_BY_INTEREST,
  GET_CONVERSATION_TALKER_BY_LOCATIONS,
  GET_CONVERSATION_TALKER_BY_SENTIMENT,
  GET_CONVERSATION_TALK_BY_MEDIA,
  GET_CONVERSATION_TOP_ENGAGEMENT,
  GET_CONVERSATION_TOP_INFLUENCER,
  GET_CONVERSATION_TOP_TALKER,
  GET_CONVERSATION_TOP_TALK_WITH_PAGINATION,
  GET_CONVERSATION_WORD_CLOUD_TALK,
  GET_AGE_GENERATION,
  GET_CONVERSATION_SUMMARY
} from './graphql/conversation/query';
import uuidNameSpace from 'uuid/v5';
import getNS from 'uuid/v4';
import { IConversationByMedia } from 'src/models/conversation';
const RequestNameSpace = getNS();

interface IGetNumericalResponse {
  conversation_getTotalGrouped: Array<IAnalyticTotalGrowth>;
}

interface IGetDayToDayConversationResponse {
  conversation_getChronologicalGrouped: Array<ChronologicalList>;
}

interface IGetConversationByMediaResponse {
  conversation_getTalkByMedia: Array<IConversationByMedia>;
}

interface IGetWordCloudsResponse {
  conversation_getWordCloudTalk: Array<IWordCloud>;
}

interface IGetSentimentAnalysisResponse {
  conversation_getSentimentAnalysis: Array<INumericOutput>;
}

interface IGetTalkerBySentimentResponse {
  conversation_getTalkerBySentiment: Array<INumericOutput>;
}

interface IGetTalkerByInterestResponse {
  conversation_getInterest: Array<INumericOutput>;
}

interface IGetTalkerByLocationResponse {
  conversation_getLocation: Array<INumericOutput>;
}

interface IGetTalkerByGenderResponse {
  conversation_getGender: Array<INumericOutput>;
}

interface IGetTopTalkerResponse {
  conversation_getTopTalker: Array<IUserEngagement>;
}

interface IGetTopInfluencerResponse {
  conversation_getTopInfluencer: Array<IUserEngagement>;
}

interface IGetTopEngagementResponse {
  conversation_getTopEngagement: Array<IUserEngagement>;
}

interface IGetListOfTalkResponse {
  conversation_getTopTalkWithPaginationV2: IRawPostmadeOutputWithPagination;
}

interface IGetStreamSummaryResponse {
  conversation_getStreamSummary: {
    requestId: string;
    summary: string;
    topic: string;
  };
}

interface IGetStreamSummaryParams extends IAdvanceSearchSocialMedia {
  projectId: string;
  start: string;
  end: string;
  objectList: Array<string>;
  timezone: number;
  granularity: string;
}

const useConversations = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const objectActive = useAppSelector(
    (state) => state.objectManagement.objectListByObjectType
  );

  const lastPromiseNumericals: any = useRef();
  const lastPromiseDayToDayConversation: any = useRef();
  const lastPromiseConversationByMedia: any = useRef();
  const lastPromiseSentimentAnalysis: any = useRef();
  const lastPromiseTalkerBySentiment: any = useRef();
  const lastPromiseTalkerByInterest: any = useRef();
  const lastPromiseTalkerByLocations: any = useRef();
  const lastPromiseTalkerByGender: any = useRef();
  const lastPromiseListOfTalk: any = useRef();
  const lastPromiseTopTalker: any = useRef();
  const lastPromiseTopInfluencer: any = useRef();
  const lastPromiseTopEngagement: any = useRef();
  const lastPromisePeakTime: any = useRef();
  const lastPromiseWordClouds: any = useRef();

  const fetchNumericals = function (parameter: IAdvanceSearchSocialMedia) {
    const response = client
      .query<IGetNumericalResponse>({
        query: GET_CONVERSATION_CONVERSATION_GROUP_TOTAL,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_CONVERSATION_GROUP_TOTAL',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        const dataGroupNumerical = response.data.conversation_getTotalGrouped;
        const talk = dataGroupNumerical.find(
          (data) => data.contentType === 'talk'
        );
        const talker = dataGroupNumerical.find(
          (data) => data.contentType === 'talker'
        );
        const positiveTalk = dataGroupNumerical.find(
          (data) => data.contentType === 'positive'
        );
        const negativeTalk = dataGroupNumerical.find(
          (data) => data.contentType === 'negative'
        );

        const mergeNumericals = {
          talk,
          talker,
          positiveTalk,
          negativeTalk
        };
        return { ...response, data: mergeNumericals };
      });
    return response;
  };

  const fetchDayToDayConversation = function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = client
      .query<IGetDayToDayConversationResponse>({
        query: GET_CONVERSATION_GROUP_CHRONOLOGICAL_CONVERSATION,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_GROUP_CHRONOLOGICAL_CONVERSATION',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        const generateDataLine = buildDataLineGroup(
          response.data.conversation_getChronologicalGrouped
        );
        return { ...response, data: generateDataLine };
      });
    return response;
  };

  const setDataColumn = function (columns: any, names: Array<string>) {
    return names.map((name) => {
      let result = {
        name: name,
        data: [] as any
      };
      const isObjectYoutubeAvailable = objectActive.find(
        (object) => object.socialMedia === 'youtube'
      );
      const ignoreYoutube = isObjectYoutubeAvailable ? '' : 'youtube';
      columns.forEach((column: any) => {
        Object.keys(column).forEach((key) => {
          if (name === key && column['socialMedia'] !== ignoreYoutube)
            result.data.push({
              x: column['socialMedia'],
              y: column[key]
            });
        });
      });
      return result;
    });
  };

  const fetchConversationByMedia = function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = client
      .query<IGetConversationByMediaResponse>({
        query: GET_CONVERSATION_TALK_BY_MEDIA,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_TALK_BY_MEDIA',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        const talkByMedia = setDataColumn(
          response.data.conversation_getTalkByMedia,
          ['talk', 'talker']
        );
        return { ...response, data: talkByMedia };
      });
    return response;
  };

  const fetchWordClouds = function (parameter: IAdvanceSearchSocialMedia) {
    const response = client
      .query<IGetWordCloudsResponse>({
        query: GET_CONVERSATION_WORD_CLOUD_TALK,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_WORD_CLOUD_TALK',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        const wordClouds = response.data.conversation_getWordCloudTalk.map(
          (word) => ({
            text: word.name,
            value: word.value
          })
        );
        const data =
          wordClouds.length > 0 ? wordClouds.map((word) => word.value) : [];
        const max = data.length > 0 ? Math.max(...data) : 0;
        const min = data.length > 0 ? Math.min(...data) : 1;
        return { ...response, data: { data: wordClouds, max, min } };
      });
    return response;
  };

  const fetchSentimentAnalysis = function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = client
      .query<IGetSentimentAnalysisResponse>({
        query: GET_CONVERSATION_SENTIMENT_ANALYSIS,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_SENTIMENT_ANALYSIS',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.conversation_getSentimentAnalysis
        };
      });
    return response;
  };

  const fetchTalkerBySentiment = function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = client
      .query<IGetTalkerBySentimentResponse>({
        query: GET_CONVERSATION_TALKER_BY_SENTIMENT,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_TALKER_BY_SENTIMENT',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.conversation_getTalkerBySentiment
        };
      });
    return response;
  };

  const fetchTalkerByInterest = function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = client
      .query<IGetTalkerByInterestResponse>({
        query: GET_CONVERSATION_TALKER_BY_INTEREST,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_TALKER_BY_INTEREST',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        return { ...response, data: response.data.conversation_getInterest };
      });
    return response;
  };

  const fetchTalkerByLocations = function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = client
      .query<IGetTalkerByLocationResponse>({
        query: GET_CONVERSATION_TALKER_BY_LOCATIONS,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_TALKER_BY_LOCATIONS',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        return { ...response, data: response.data.conversation_getLocation };
      });
    return response;
  };

  const fetchTalkerByGender = function (parameter: IAdvanceSearchSocialMedia) {
    const response = client
      .query<IGetTalkerByGenderResponse>({
        query: GET_CONVERSATION_FOLLOWERS_BY_GENDER,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_FOLLOWERS_BY_GENDER',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        return { ...response, data: response.data.conversation_getGender };
      });
    return response;
  };

  const fetchTopTalker = function (parameter: IAdvanceSearchSocialMedia) {
    const response = client
      .query<IGetTopTalkerResponse>({
        query: GET_CONVERSATION_TOP_TALKER,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_TOP_TALKER',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        return { ...response, data: response.data.conversation_getTopTalker };
      });
    return response;
  };

  const fetchTopInfluencer = function (parameter: IAdvanceSearchSocialMedia) {
    const response = client
      .query<IGetTopInfluencerResponse>({
        query: GET_CONVERSATION_TOP_INFLUENCER,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_TOP_INFLUENCER',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.conversation_getTopInfluencer
        };
      });
    return response;
  };

  const fetchTopEngagement = function (parameter: IAdvanceSearchSocialMedia) {
    const response = client
      .query<IGetTopEngagementResponse>({
        query: GET_CONVERSATION_TOP_ENGAGEMENT,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_TOP_ENGAGEMENT',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.conversation_getTopEngagement
        };
      });
    return response;
  };

  const fetchListOftalk = function (parameter: IAdvanceSearchSocialMedia) {
    const response = client
      .query<IGetListOfTalkResponse>({
        query: GET_CONVERSATION_TOP_TALK_WITH_PAGINATION,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_TOP_TALK_WITH_PAGINATION',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.conversation_getTopTalkWithPaginationV2
        };
      });
    return response;
  };

  const getListOftalk = async function (payload: IAdvanceSearchSocialMedia) {
    dispatch(reducerUpdateConversationListOfTalkLoading(true));
    const response = await fetchListOftalk(payload);
    dispatch(reducerUpdateConversationListOfTalk(response));
  };

  const fetchPeakTime = function (parameter: IAdvanceSearchSocialMedia) {
    const response = client
      .query({
        query: GET_CONVERSATION_AUDIENCE_ENGAGEMENT,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_AUDIENCE_ENGAGEMENT',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        const { max, min, peakTimeValue } =
          response.data.conversation_getAudienceEngagement;
        const data = peakTimeValue ? sortingDayPeakTime(peakTimeValue) : [];
        return { ...response, data: { data, max, min } };
      });
    return response;
  };

  const getNumericals = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchNumericals(parameter);
    lastPromiseNumericals.current = response;
    if (response === lastPromiseNumericals.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationNumericals(response));
      });
    }
  };

  const getDayToDayConversation = function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    dispatch(reducerUpdateConversationDayToDayLoading(true));
    const response = fetchDayToDayConversation(parameter);
    lastPromiseDayToDayConversation.current = response;
    if (response === lastPromiseDayToDayConversation.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationDayToDayConversation(response));
      });
    }
  };

  const getConversationByMedia = function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = fetchConversationByMedia(parameter);
    lastPromiseConversationByMedia.current = response;
    if (response === lastPromiseConversationByMedia.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationByMedia(response));
      });
    }
  };

  const getSentimentAnalysis = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchSentimentAnalysis(parameter);
    lastPromiseSentimentAnalysis.current = response;
    if (response === lastPromiseSentimentAnalysis.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationSentimentAnalysis(response));
      });
    }
  };

  const getTalkerBySentiment = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchTalkerBySentiment(parameter);
    lastPromiseTalkerBySentiment.current = response;
    if (response === lastPromiseTalkerBySentiment.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationTalkerBySentiment(response));
      });
    }
  };

  const getTalkerByInterest = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchTalkerByInterest(parameter);
    lastPromiseTalkerByInterest.current = response;
    if (response === lastPromiseTalkerByInterest.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationTalkerByInterest(response));
      });
    }
  };

  const getTalkerByLocations = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchTalkerByLocations(parameter);
    lastPromiseTalkerByLocations.current = response;
    if (response === lastPromiseTalkerByLocations.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationTalkerByLocations(response));
      });
    }
  };

  const getTalkerByGender = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchTalkerByGender(parameter);
    lastPromiseTalkerByGender.current = response;
    if (response === lastPromiseTalkerByGender.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationTalkerByGender(response));
      });
    }
  };

  const getListOfTalk = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchListOftalk(parameter);
    lastPromiseListOfTalk.current = response;
    if (response === lastPromiseListOfTalk.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationListOfTalk(response));
      });
    }
  };

  const getTopTalker = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchTopTalker(parameter);
    lastPromiseTopTalker.current = response;
    if (response === lastPromiseTopTalker.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationTopTalker(response));
      });
    }
  };

  const getTopInfluencer = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchTopInfluencer(parameter);
    lastPromiseTopInfluencer.current = response;
    if (response === lastPromiseTopInfluencer.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationTopInfluencer(response));
      });
    }
  };

  const getTopEngagement = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchTopEngagement(parameter);
    lastPromiseTopEngagement.current = response;
    if (response === lastPromiseTopEngagement.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationTopEngagement(response));
      });
    }
  };

  const getPeakTime = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchPeakTime(parameter);
    lastPromisePeakTime.current = response;
    if (response === lastPromisePeakTime.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationPeakTime(response));
      });
    }
  };

  const getWordClouds = function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchWordClouds(parameter);
    lastPromiseWordClouds.current = response;
    if (response === lastPromiseWordClouds.current) {
      response.then((response) => {
        dispatch(reducerUpdateConversationWordClouds(response));
      });
    }
  };

  const getConversationAllMetric = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    dispatch(reducerUpdateConversationAllMetricLoading(true));
    getConversationAllMetricPerDay(parameter);
    getDayToDayConversation(parameter);
    getNumericals(parameter);
  };

  const getConversationAllMetricPerDay = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    dispatch(reducerUpdateConversationAllMetricLoadingPerDay(true));
    getConversationByMedia(parameter);
    getListOfTalk(parameter);
    getListOftalk(parameter);
    getPeakTime(parameter);
    getSentimentAnalysis(parameter);
    getTalkerByGender(parameter);
    getTalkerByInterest(parameter);
    getTalkerByLocations(parameter);
    getTalkerBySentiment(parameter);
    getTopEngagement(parameter);
    getTopInfluencer(parameter);
    getTopTalker(parameter);
    getWordClouds(parameter);
  };

  const useStreamSummary = (
    options?: LazyQueryHookOptions<
      IGetStreamSummaryResponse,
      IGetStreamSummaryParams
    >
  ) => {
    return useLazyQuery<IGetStreamSummaryResponse, IGetStreamSummaryParams>(
      GET_CONVERSATION_SUMMARY,
      {
        ...options
      }
    );
  };

  return {
    getConversationAllMetric,
    getListOftalk,
    getConversationAllMetricPerDay,
    getSentimentAnalysis,
    getDayToDayConversation,
    getNumericals,
    useStreamSummary
  };
};

export default useConversations;
