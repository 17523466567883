import { useApolloClient } from '@apollo/react-hooks';
import { useAppDispatch } from 'src/app/hooks';
import {
  reducerUpdateAllMetricLoading,
  reducerUpdateAnalyticTotalEngagement,
  reducerUpdateAnalyticTotalFollowers,
  reducerUpdateAnalyticTotalReach,
  reducerUpdateAnalyticTotalTalk,
  reducerUpdateChronologicalEngagementPeformance,
  reducerUpdateChronologicalFollowersGrowth,
  reducerUpdateChronologicalReachLoading,
  reducerUpdateChronologicalReachPeformance,
  reducerUpdateChronologicalTalkPeformance
} from 'src/features/sosmedOverview';
import {
  IAdvanceSearchSocialMedia,
  IAnalyticTotalGrowth,
  IChronological,
  IGranularity
} from 'src/models/general';
import {
  GET_OVERVIEW_CHRONOLOGICAL_AUDIENCE,
  GET_OVERVIEW_CHRONOLOGICAL_ENGAGEMENT,
  GET_OVERVIEW_CHRONOLOGICAL_REACH,
  GET_OVERVIEW_CHRONOLOGICAL_TALK_OVERVIEW,
  GET_OVERVIEW_TOTAL_ENGAGEMENT,
  GET_OVERVIEW_TOTAL_FOLLOWERS,
  GET_OVERVIEW_TOTAL_REACH,
  GET_OVERVIEW_TOTAL_TALK
} from './graphql/overview/query';
import { keyMappingChronological } from 'src/utils/keyMappingChronological';
import { ChronologicalList } from 'src/utils/buildDataLineGroup';

interface IGetEngagementResponse {
  overview_getEngagement: IAnalyticTotalGrowth;
}

interface IGetTotalFollowersResponse {
  overview_getTotalFollower: IAnalyticTotalGrowth;
}

export interface IGetTotalTalkResponse {
  overview_getTalk: IAnalyticTotalGrowth;
}

interface IGetTotalReachResponse {
  overview_getReach: IAnalyticTotalGrowth;
}
interface IChronologicalEngagement {
  overview_getChronologicalEngagement: Array<IChronological>;
}

interface IChronologicalFollowers {
  overview_getChronologicalAudience: Array<IChronological>;
}

interface IChronologicalTalker {
  overview_getChronologicalTalkOverview: Array<IChronological>;
}

interface IChronologicalReach {
  overview_getChronologicalReach: Array<IChronological>;
}

const useOverview = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  const fetchTotalEngagement = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    return await client
      .query<IGetEngagementResponse>({
        query: GET_OVERVIEW_TOTAL_ENGAGEMENT,
        variables: payload
      })
      .then((response) => {
        return { ...response, data: response.data.overview_getEngagement };
      });
  };

  const getTotalEngagement = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    const response = await fetchTotalEngagement(payload);
    if (response.error) {
      console.log(response.error);
      return;
    }
    dispatch(reducerUpdateAnalyticTotalEngagement(response));
  };

  const fetchTotalFollowers = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    return await client
      .query<IGetTotalFollowersResponse>({
        query: GET_OVERVIEW_TOTAL_FOLLOWERS,
        variables: payload
      })
      .then((response) => {
        return { ...response, data: response.data.overview_getTotalFollower };
      });
  };

  const getTotalFollowers = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    const response = await fetchTotalFollowers(payload);
    if (response.error) {
      console.log(response.error);
      return;
    }
    dispatch(reducerUpdateAnalyticTotalFollowers(response));
  };

  const fetchTotalTalk = async function (payload: IAdvanceSearchSocialMedia) {
    return await client
      .query<IGetTotalTalkResponse>({
        query: GET_OVERVIEW_TOTAL_TALK,
        variables: payload
      })
      .then((response) => {
        return { ...response, data: response.data.overview_getTalk };
      });
  };

  const getTotalTalk = async function (payload: IAdvanceSearchSocialMedia) {
    const response = await fetchTotalTalk(payload);
    if (response.error) {
      console.log(response.error);
      return;
    }
    dispatch(reducerUpdateAnalyticTotalTalk(response));
  };

  const fetchTotalReach = async function (payload: IAdvanceSearchSocialMedia) {
    return await client
      .query<IGetTotalReachResponse>({
        query: GET_OVERVIEW_TOTAL_REACH,
        variables: payload
      })
      .then((response) => {
        return { ...response, data: response.data.overview_getReach };
      });
  };

  const getTotalReach = async function (payload: IAdvanceSearchSocialMedia) {
    const granularity: IGranularity = {
      unit: 'day',
      value: 1
    };
    const response = await fetchTotalReach({ ...payload });
    if (response.error) {
      console.log(response.error);
      return;
    }
    dispatch(reducerUpdateAnalyticTotalReach(response));
  };

  const fetchChronologicalEngagement = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    return await client
      .query<IChronologicalEngagement>({
        query: GET_OVERVIEW_CHRONOLOGICAL_ENGAGEMENT,
        variables: payload
      })
      .then((response) => {
        const dataMapping = keyMappingChronological(
          response.data.overview_getChronologicalEngagement
        );
        return { ...response, data: dataMapping };
      });
  };

  const getChronologicalEngagement = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    const response = await fetchChronologicalEngagement(payload);
    if (response.error) {
      console.log(response.error);
      return;
    }
    dispatch(reducerUpdateChronologicalEngagementPeformance(response));
  };

  const fetchChronologicalFollowers = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    return await client
      .query<IChronologicalFollowers>({
        query: GET_OVERVIEW_CHRONOLOGICAL_AUDIENCE,
        variables: payload
      })
      .then((response) => {
        // const dataMapping = keyMappingChronological(
        //   response.data.overview_getChronologicalAudience
        // );

        const keyMapping = response.data.overview_getChronologicalAudience.map(
          (chronological) => {
            return {
              contentType: chronological.name,
              values: chronological.values
            };
          }
        );
        return {
          ...response,
          data: keyMapping
        };
      });
  };

  const getChronologicalFollowers = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    const response = await fetchChronologicalFollowers(payload);
    if (response.error) {
      console.log(response.error);
      return;
    }
    dispatch(reducerUpdateChronologicalFollowersGrowth(response));
  };

  const fetchChronologicalTalker = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    return await client
      .query<IChronologicalTalker>({
        query: GET_OVERVIEW_CHRONOLOGICAL_TALK_OVERVIEW,
        variables: payload
      })
      .then((response) => {
        const dataMapping = keyMappingChronological(
          response.data.overview_getChronologicalTalkOverview
        );
        return { ...response, data: dataMapping };
      });
  };

  const getChronologicalTalker = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    const response = await fetchChronologicalTalker(payload);
    if (response.error) {
      console.log(response.error);
      return;
    }
    dispatch(reducerUpdateChronologicalTalkPeformance(response));
  };

  const fetchChronologicalReach = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    return await client
      .query<IChronologicalReach>({
        query: GET_OVERVIEW_CHRONOLOGICAL_REACH,
        variables: payload
      })
      .then((response) => {
        const dataMapping = keyMappingChronological(
          response.data.overview_getChronologicalReach
        );
        return { ...response, data: dataMapping };
      });
  };

  const getChronologicalReach = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    const finalPayload = { ...payload };
    dispatch(reducerUpdateChronologicalReachLoading(true));
    const response = await fetchChronologicalReach(finalPayload);
    if (response.error) {
      console.log(response.error);
      return;
    }
    dispatch(reducerUpdateChronologicalReachPeformance(response));
  };

  const getAllService = (payload: IAdvanceSearchSocialMedia) => {
    dispatch(reducerUpdateAllMetricLoading(true));
    getTotalEngagement(payload);
    getTotalFollowers(payload);
    getTotalTalk(payload);
    getTotalReach(payload);
    getChronologicalEngagement(payload);
    getChronologicalFollowers(payload);
    getChronologicalTalker(payload);
    getChronologicalReach(payload);
  };

  return {
    getTotalEngagement,
    getTotalFollowers,
    getTotalTalk,
    getTotalReach,
    getAllService,
    getChronologicalReach,
    getChronologicalEngagement,
    getChronologicalFollowers,
    getChronologicalTalker
  };
};

export default useOverview;
