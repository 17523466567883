import gql from 'graphql-tag';
import { analyticArguments, analyticArgumentsQuery } from '../analyticArgument';
import { analyticArgumentsQuery as comparisonAnalyticArgumentsQuery } from '../comparison/query';
import { analyticArguments as comparisonAnalyticArguments } from '../comparison/query';

export const GET_GOOGLE_REVIEW_TOTAL_REVIEWS = gql`
  query googleReview_getGoogleReview(
  ${analyticArgumentsQuery}
  ) {
    googleReview_getGoogleReview(
      ${analyticArguments}
    ) {
      name
      value
    }
  }
`;

export const GET_GOOGLE_REVIEW_AVG_RATING = gql`
  query googleReview_getGoogleRating(
  ${analyticArgumentsQuery}
  ) {
    googleReview_getGoogleRating(
    ${analyticArguments}
    ) {
      name
      value
    }
  }
`;

export const GET_GOOGLE_REVIEW_CHRONOLOGICAL_RATING = gql`
  query googleReview_getChronologicalRating(
   ${analyticArgumentsQuery}
  ) {
    googleReview_getChronologicalRating(
      ${analyticArguments}
    ) {
      contentType
      values {
        timestamp
        value
      }
    }
  }
`;

export const GET_GOOGLE_REVIEW_CHRONOLOGICAL_REVIEWS = gql`
  query googleReview_getChronologicalReviews(
    ${analyticArgumentsQuery}
  ) {
    googleReview_getChronologicalReviews(
      ${analyticArguments}
    ) {
      contentType
      values {
        timestamp
        value
      }
    }
  }
`;

export const GET_GOOGLE_REVIEW_RATING_DISTRIBUTION = gql`
  query googleReview_getRatingDistribution(
    ${analyticArgumentsQuery}
  ) {
    googleReview_getRatingDistribution(
      ${analyticArguments}
    ) {
      timeStamp
      oneStar
      twoStar
      threeStar
      fourStar
      fiveStar
    }
  }
`;

// COMPARISON SECTION
export const GET_GOOGLE_REVIEW_COMPARISON_TOTAL_RATING = gql`
  query googleComparison_getTotalRating(
    ${comparisonAnalyticArgumentsQuery}
  ) {
    googleComparison_getTotalRating(
      ${comparisonAnalyticArguments}
    ) {
      name
      value
    }
  }
`;

export const GET_GOOGLE_REVIEW_COMPARISON_TOTAL_REVIEWS = gql`
  query googleComparison_getTotalReviews(
  ${comparisonAnalyticArgumentsQuery}
  ) {
    googleComparison_getTotalReviews(
      ${comparisonAnalyticArguments}
    ) {
      name
      value
    }
  }
`;

export const GET_GOOGLE_REVIEW_COMPARISON_CHRONOLOGICAL_REVIEWS = gql`
  query googleComparison_getChronologicalReviews(
  ${comparisonAnalyticArgumentsQuery}
  ) {
    googleComparison_getChronologicalReviews(
      ${comparisonAnalyticArguments}
    ) {
      name
      values {
        timestamp
        value
      }
    }
  }
`;

export const GET_GOOGLE_REVIEW_COMPARISON_CHRONOLOGICAL_RATINGS = gql`
  query GoogleComparison_getChronologicalRatings(
    ${comparisonAnalyticArgumentsQuery}
  ) {
    googleComparison_getChronologicalRatings(
      ${comparisonAnalyticArguments}
    ) {
      name
      values {
        timestamp
        value
      }
    }
  }
`;

export const GET_GOOGLE_REVIEW_COMPARISON_LIST = gql`
  query googleComparison_getComparisonNamespaceList($projectId: Id!) {
    googleComparison_getComparisonNamespaceList(projectId: $projectId) {
      id
      namespace
      displayName
      isDefault
      sourceType
      createdAt
      colors
    }
  }
`;
