/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Popover,
  Stack,
  styled,
  SvgIconTypeMap,
  Typography,
  useTheme,
  CircularProgress,
  TextField,
  Divider,
  Select,
  MenuItem,
  Tooltip,
  Chip,
  Autocomplete
} from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TSocialMedia } from 'src/models/general';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IObjectInput, IObject } from 'src/models/objectManagement';
import { useDebounce } from 'src/hooks/useDebounce';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import StreamPreview from './components/StreamPreview';
import useObjectManagement from 'src/services/useObjectManagement';
import useOnboarding from 'src/services/useOnboarding';
import {
  reducerUpdateObjectList,
  reducerClearObjectManagementStreamPreview
} from 'src/features/objectManagement';
import languages from './languages';
import FilterListIcon from '@mui/icons-material/FilterList';
import emptyfile from 'src/assets/image/emptyfile.svg';
import { withStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import TooltipCustomize from 'src/components/TooltipCustomize';
import Confirmation from '../Confirmation';
import GetSocialMediaIcon from '../GetSocialMediaIcon';
import { useApolloClient } from '@apollo/client';
import { GET_OBJECTS_WITH_ENTITY } from 'src/services/graphql/objectManagement/query';
import TikTokIcon from '../TikTokIcon/TikTokIcon';

interface Props {
  onClose?: () => void;
  tempValues?: IObject;
  isEditing?: boolean;
  dataList?: any;
}

interface ISocialMedia {
  id: TSocialMedia;
  label: string;
  icon:
    | (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
      })
    | any;
  disabled: boolean;
}

interface ITypeInput {
  id: 'keyword' | 'hashtag';
  label: string;
  isActive: boolean;
  value: string;
  disabled: boolean;
}

const CustomButton = styled(Button)(
  ({ theme }) => `
      height: ${theme.spacing(4)};
      background-color: ${theme.palette.grey[200]};
      border: 1px solid ${theme.palette.divider};
      border-color: ${theme.palette.common.black}
      color: ${theme.palette.primary.main};
      &:hover{
        background-color: ${theme.palette.grey[300]};
      }
    `
);

const ButtonInputType = styled(Button)(
  ({ theme }) => `
        background: ${theme.palette.common.black};
        height: 22.61px;
        color: ${theme.colors.primary.lighter}
      `
);

const ButtonInputTypeInactive = styled(Button)(
  ({ theme }) => `
    height: 22.61px;
    &:hover {
        background-color: unset;
    }
    `
);

const ListItemButtonCustom = styled(ListItemButton)(
  ({ theme }) => `
      &:hover {
        background-color: ${theme.palette.primary.light}
      }
    `
);

const CustomAutocomplete = withStyles({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input':
      {
        padding: 0
      }
  }
})((props: any) => <Autocomplete {...props} />);

const listOptionSocialMedia = (objectType: string): Array<ISocialMedia> => [
  {
    id: 'twitter',
    label: 'Twitter',
    icon: TwitterIcon,
    disabled: false
  },
  {
    id: 'facebook',
    label: 'Facebook',
    icon: FacebookIcon,
    disabled: false
  },
  {
    id: 'instagram',
    label: 'Instagram',
    icon: InstagramIcon,
    disabled: false
  },
  {
    id: 'youtube',
    label: 'Youtube',
    icon: YouTubeIcon,
    disabled: false
  },
  {
    id: 'tiktok',
    label: 'TikTok',
    icon: TikTokIcon,
    disabled: false
  }
];

const InputKeywordOrHashtag: FC<Props> = ({
  onClose,
  tempValues,
  isEditing = false,
  dataList
}) => {
  const sampleLocation = useLocation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const { getObjectManagementStreamPreview } = useObjectManagement();
  const streamPreview = useAppSelector(
    (state) => state.objectManagement.streamPreview
  );
  const [socialMedia, setSocialMedia] = useState<TSocialMedia>(
    isEditing ? tempValues.socialMedia : 'twitter'
  );
  const [showFilter, setShowFilter] = useState<boolean>(false);
  function validationSchema() {
    return Yup.object({
      objectName: Yup.string().required()
    });
  }
  const project = useAppSelector((state) => state.users.project);
  const { createObjects, responseCreateObjects } = useOnboarding();
  const { getObjectList } = useObjectManagement();
  const { postEditObject } = useObjectManagement();
  const [openAddConfirmation, setOpenAddConfirmation] =
    useState<boolean>(false);

  const objectList = useAppSelector(
    (state) => state.objectManagement.objectList.data
  );
  const objectListKeywordAndHashtag = useMemo((): Array<IObject> => {
    return objectList.filter((object) => object.streamType === 'keyword');
  }, [objectList]);

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    values
  } = useFormik<IObjectInput>({
    initialValues: {
      content: '',
      displayPic: '',
      excludes: [],
      extraContent: '',
      includes: [],
      // isHashtag: false,
      isHashtag:
        sampleLocation.pathname === '/social_media/campaign' ? true : false,
      languages: '',
      objectName: '',
      socialMedia: socialMedia,
      streamType: 'keyword'
      // streamType:
      //   sampleLocation.pathname === '/social_media/campaign'
      //     ? 'hashtag'
      //     : 'keyword'
    },
    validationSchema: validationSchema(),
    onSubmit: (values) => {
      isObjectAlreadyIncluded(values)
        ? setOpenAddConfirmation(true)
        : saveObject(values);
    }
  });

  const handleIncludesLocal = (value: string[]) => {
    setFieldTouched('includes', true);
    setFieldValue('includes', value);
  };
  const handleExcludesLocal = (value: string[]) => {
    setFieldTouched('excludes', true);
    setFieldValue('excludes', value);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChangeSocialMedia = (socialMediaId: TSocialMedia) => {
    setSocialMedia(socialMediaId);
    handleClose();
  };

  const [inputType, setInputType] = useState<Array<ITypeInput>>([
    {
      id: 'keyword',
      isActive: isEditing && tempValues.isHashtag ? false : true,
      label: 'Keyword',
      value: 'keyword',
      disabled: isEditing && tempValues.isHashtag ? true : false
    },
    {
      id: 'hashtag',
      isActive: isEditing && tempValues.isHashtag ? true : false,
      label: 'Hashtag',
      value: 'hashtag',
      disabled: isEditing ? (tempValues.isHashtag ? false : true) : false
    }
  ]);

  const handleChangeInputType = (id: string) => {
    setInputType((prevPeriodes) =>
      prevPeriodes.map((period) => {
        if (period.id === id) return { ...period, isActive: true };
        return { ...period, isActive: false };
      })
    );
    setFieldValue('isHashtag', !values.isHashtag);
    setFieldValue('streamType', id);
  };

  const [debouncedState, setDebouncedState] = useDebounce();

  const handleChangeKeyword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.persist();
    const keyword = event.target.value;
    setDebouncedState(() => keyword);
  };

  const isObjectAlreadyIncluded = (objectInput: IObjectInput) => {
    return objectListKeywordAndHashtag.some(
      (object) =>
        object.objectName === objectInput.objectName &&
        object.socialMedia === objectInput.socialMedia &&
        object.isHashtag === objectInput.isHashtag
    );
  };

  const saveObject = (values: IObjectInput) => {
    const { streamType, ...objectInput } = values;
    const objectName = objectInput.isHashtag
      ? `#${objectInput.objectName}`
      : objectInput.objectName;
    const payload = {
      projectId: project.id,
      streamType: 'keyword',
      objectInput: [
        {
          ...objectInput,
          content: objectName,
          objectName,
          languages: values.languages !== '' ? [values.languages] : []
        }
      ]
    };
    if (!isEditing) {
      createObjects({
        variables: payload
      }).then(() => {
        getObjectList(project.id).then(() => {
          dispatch(reducerClearObjectManagementStreamPreview());
          onClose();
        });
        client.refetchQueries({ include: [GET_OBJECTS_WITH_ENTITY] });
      });
    } else {
      postEditObject({
        variables: {
          ...values,
          objectId: tempValues.id,
          keywords: [],
          objectName: objectName
        }
      }).then((response) => {
        if (!response?.data) return;
        const { objectManagement_editObject } = response.data;
        const newObject = dataList.map((object) =>
          object.id !== objectManagement_editObject.id
            ? object
            : {
                ...objectManagement_editObject,
                objectName: objectManagement_editObject.objectName
              }
        );
        dispatch(
          reducerUpdateObjectList({
            data: newObject,
            loading: false,
            networkStatus: 1
          })
        );
        onClose();
        client.refetchQueries({ include: [GET_OBJECTS_WITH_ENTITY] });
      });
    }
  };

  useMemo(() => {
    if (values.socialMedia === 'instagram' || values.objectName === '') return;
    const objectName = values.isHashtag
      ? `#${values.objectName}`
      : values.objectName;
    const payload = {
      ...values,
      objectName,
      languages: [values.languages],
      includes: values.includes.length > 0 ? values.includes : [],
      excludes: values.excludes.length > 0 ? values.excludes : []
    };
    getObjectManagementStreamPreview(payload);
  }, [
    debouncedState,
    values.languages,
    values.socialMedia,
    values.includes,
    values.excludes
  ]);

  useEffect(() => {
    setFieldValue('socialMedia', socialMedia);
  }, [socialMedia]);

  const getObjectTypeSymbol = (isHashtag: boolean) => {
    if (isHashtag) return '#';
    return '~';
  };

  const removeHashtag = (text: string) => {
    if (text.charAt(0) === '#') {
      return text.substring(1);
    }
    return text;
  };

  useEffect(() => {
    if (sampleLocation.pathname !== '/social_media/campaign') return;
    setInputType([
      {
        id: 'hashtag',
        isActive: true,
        label: 'Hashtag',
        value: 'hashtag',
        disabled: false
      }
    ]);
    setFieldValue('isHashtag', true);
    setFieldValue('streamType', 'hashtag');
  }, [sampleLocation]);

  useEffect(() => {
    if (!isEditing) return;
    setFieldValue(
      'objectName',
      isEditing ? removeHashtag(tempValues.objectName) : ''
    );
    setFieldValue('content', isEditing ? tempValues.content : '');
    setFieldValue('extraContent', isEditing ? tempValues.extraContent : '');
    setFieldValue('isHashtag', isEditing ? tempValues.isHashtag : false);
    setFieldValue('streamType', isEditing ? tempValues.streamType : 'keyword');
    setFieldValue('includes', isEditing ? tempValues.includes : '');
    setFieldValue('excludes', isEditing ? tempValues.excludes : '');
    setFieldValue('languages', isEditing ? tempValues.languages[0] ?? '' : '');
    setFieldValue(
      'socialMedia',
      isEditing ? tempValues.socialMedia : socialMedia
    );
  }, [isEditing]);
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box
            sx={{
              height: theme.spacing(4),
              width: '162px',
              background: '#F5F5F5',
              borderRadius: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '5px 6px 5px 6px'
            }}
          >
            <Grid container>
              {inputType.map(({ id, label, isActive, disabled }) => {
                if (isActive)
                  return (
                    <Grid
                      item
                      lg={6}
                      key={id}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <ButtonInputType
                        variant="contained"
                        fullWidth
                        disabled={disabled}
                      >
                        {label}
                      </ButtonInputType>
                    </Grid>
                  );
                return (
                  <Grid item lg={6} key={id}>
                    <ButtonInputTypeInactive
                      disabled={socialMedia === 'instagram' ? true : disabled}
                      variant="text"
                      fullWidth
                      onClick={() => handleChangeInputType(id)}
                    >
                      {label}
                    </ButtonInputTypeInactive>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <CustomButton
            variant="contained"
            onClick={handleClick}
            startIcon={
              <GetSocialMediaIcon socialMedia={socialMedia} color={'primary'} />
            }
            endIcon={
              <ArrowDropDownIcon
                fontSize="small"
                color="primary"
                sx={{ ml: 1 }}
              />
            }
          >
            <Typography
              variant="subtitle1"
              color="black"
              sx={{
                fontWeight: 600,
                color: '#051C26'
              }}
            >
              {socialMedia}
            </Typography>
          </CustomButton>
        </Stack>
        <Grid container spacing={1}>
          <Grid item lg={11}>
            <FormControl fullWidth size="medium">
              <OutlinedInput
                name="objectName"
                defaultValue={
                  isEditing ? removeHashtag(tempValues.objectName) : null
                }
                onChange={(e) => {
                  handleChange(e);
                  handleChangeKeyword(e);
                }}
                startAdornment={
                  <Typography sx={{ ml: '4px' }}>
                    {getObjectTypeSymbol(values.isHashtag)}
                  </Typography>
                }
              />
              <FormHelperText
                error
                variant="outlined"
                margin="dense"
                sx={{ ml: 0 }}
              >
                {errors.objectName && touched.objectName && errors.objectName}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item lg={1}>
            <Tooltip title="Filter" placement="top-start">
              <Button
                variant="contained"
                sx={{
                  borderRadius: '6px',
                  background: theme.palette.primary.dark
                }}
                onClick={() => {
                  setShowFilter((prev) => !prev);
                }}
                fullWidth
              >
                <FilterListIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: theme.spacing(2)
          }}
        >
          <Grid container spacing={3}>
            <Grid item lg={4} sx={{ display: showFilter ? 'block' : 'none' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600
                }}
              >
                Filter
              </Typography>
              <Divider sx={{ m: theme.spacing(1.5, 0) }} />
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <Stack
                    direction="row"
                    spacing={0.5}
                    sx={{ mb: theme.spacing(1) }}
                  >
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                      Includes
                    </Typography>
                    <TooltipCustomize
                      title='i.e - result must contain "Cat" or "Dog", press enter to add phrases.'
                      placement="top-start"
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        sx={{ transform: 'translateY(1px)', fontSize: 14 }}
                      />
                    </TooltipCustomize>
                  </Stack>

                  <CustomAutocomplete
                    multiple
                    id="keyword-includes"
                    size="small"
                    options={[]}
                    defaultValue={[]}
                    freeSolo
                    value={values.includes}
                    renderTags={(values: Array<any>, getTagProps) =>
                      values.map((option: string, index: number) => (
                        <Chip
                          key={`${option}-${index}-includes`}
                          variant="outlined"
                          label={<Typography>{option}</Typography>}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={(_e, value) => {
                      handleIncludesLocal(value);
                    }}
                    forcePopupIcon={false}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="includes"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        placeholder="Separated by Enter"
                        error={touched.includes && Boolean(errors.includes)}
                        onPaste={(event) => {
                          const includes = event.clipboardData.getData('text');
                          const splitKeyword = includes.split(',');
                          const trimAndFilterKeyword = splitKeyword
                            .filter((word) => word.trim().length > 1)
                            .map((word) => word.trim());
                          const value = [
                            ...values.includes,
                            ...trimAndFilterKeyword
                          ];
                          handleIncludesLocal(value);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12}>
                  <Stack
                    direction="row"
                    spacing={0.5}
                    sx={{ mb: theme.spacing(1) }}
                  >
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                      Excludes
                    </Typography>
                    <TooltipCustomize
                      title={`i.e - result won't contain "Bird" or "Bee", press enter to add phrases.`}
                      placement="top-start"
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        sx={{ transform: 'translateY(1px)', fontSize: 14 }}
                      />
                    </TooltipCustomize>
                  </Stack>
                  <CustomAutocomplete
                    multiple
                    id="keyword-excludes"
                    size="small"
                    options={[]}
                    defaultValue={[]}
                    freeSolo
                    value={values.excludes}
                    renderTags={(values: Array<any>, getTagProps) =>
                      values.map((option: string, index: number) => (
                        <Chip
                          key={`${option}-${index}-excludes`}
                          variant="outlined"
                          label={<Typography>{option}</Typography>}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={(_e, value) => {
                      handleExcludesLocal(value);
                    }}
                    forcePopupIcon={false}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="excludes"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        placeholder="Separated by Enter"
                        error={touched.excludes && Boolean(errors.excludes)}
                        onPaste={(event) => {
                          const excludes = event.clipboardData.getData('text');
                          const splitKeyword = excludes.split(',');
                          const trimAndFilterKeyword = splitKeyword
                            .filter((word) => word.trim().length > 1)
                            .map((word) => word.trim());
                          const value = [
                            ...values.excludes,
                            ...trimAndFilterKeyword
                          ];
                          handleExcludesLocal(value);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Languages{' '}
                  </Typography>
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{ mt: theme.spacing(1) }}
                  >
                    <Select
                      id="languages"
                      name="languages"
                      defaultValue={isEditing ? tempValues.languages[0] : null}
                      onChange={handleChange}
                    >
                      {languages.map(({ label, value }) => (
                        <MenuItem key={value} id={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={showFilter ? 8 : 12}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600
                }}
              >
                Stream Preview
              </Typography>
              <Divider sx={{ mt: theme.spacing(1.5) }} />
              <Box
                sx={{
                  height: theme.spacing(30.5),
                  background: theme.palette.grey[100],
                  padding: theme.spacing(1.5),
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  borderRadius: theme.spacing(0, 0, 1, 1)
                }}
              >
                {values.socialMedia === 'instagram' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      flexDirection: 'column'
                    }}
                  >
                    <img
                      src={emptyfile}
                      alt="empty"
                      style={{
                        width: theme.spacing(8),
                        height: theme.spacing(6),
                        marginBottom: theme.spacing(1)
                      }}
                    />
                    <Typography variant="subtitle2" color="GrayText">
                      Stream Preview is not available for Instagram
                    </Typography>
                  </Box>
                ) : streamPreview.loading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%'
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  streamPreview.data &&
                  streamPreview.data.dataView.map((stream) => (
                    <Box sx={{ mb: 1 }}>
                      <StreamPreview
                        src={stream.displayPicture}
                        content={stream.caption}
                        originalPost={stream.url}
                        name={stream.displayName}
                      />
                    </Box>
                  ))
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          mt={3}
        >
          <Button
            variant="contained"
            sx={{ width: theme.spacing(15), height: theme.spacing(5) }}
            type="submit"
            disabled={
              values.objectName.length < 1 || responseCreateObjects.loading
            }
            endIcon={
              responseCreateObjects.loading ? (
                <CircularProgress size={20} />
              ) : null
            }
          >
            Save
          </Button>
        </Stack>
      </form>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List>
          {listOptionSocialMedia(values.streamType).map((socialMedia) => (
            <ListItem disablePadding key={socialMedia.id}>
              <ListItemButtonCustom
                disabled={socialMedia.disabled}
                onClick={() => handleChangeSocialMedia(socialMedia.id)}
              >
                <ListItemIcon>{<socialMedia.icon />}</ListItemIcon>
                <ListItemText primary={socialMedia.label} />
              </ListItemButtonCustom>
            </ListItem>
          ))}
        </List>
      </Popover>
      <Confirmation
        title={'Add this object?'}
        labelButton={'Add'}
        message={`The keyword/hashtag
         you want to add has already been added.`}
        open={openAddConfirmation}
        onClose={() => setOpenAddConfirmation(false)}
        onOk={() => {
          saveObject(values);
        }}
      />
    </Box>
  );
};

export default InputKeywordOrHashtag;
