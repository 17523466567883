import { GraphQLError } from 'graphql';
import { IObject } from './objectManagement';

export type TSocialMedia =
  | 'youtube'
  | 'twitter'
  | 'facebook'
  | 'instagram'
  | 'tiktok'
  | 'linkedin'
  | 'googleReview'
  | 'telegram'
  | 'whatsapp';

export const TOTAL_SOCIAL_MEDIA = 9;

export type TStreamType = 'account' | 'keyword' | 'hashtag';

export interface ILabel {
  id: string;
  name: string;
  colorType?: string;
  displayName: string;
  isActive: boolean;
  createdAt: Date;
}

export type TSentiment = 'positive' | 'negative' | 'neutral';
export type TPostOwnership = 'postmade' | 'talk' | 'reply';
export type TContentType = 'text' | 'image' | 'video' | 'others';
export type TValidation = 'validated' | 'unvalidated';
export type TLabel = true | false;
export type TVisibility = 'show' | 'hidden';

export interface ITableOutput {
  header: Array<string>;
  body: Array<ITableRow>;
  rowCount: number;
}

export interface ITableRow {
  cells: Array<ICell>;
}

export interface ICell {
  cellName: string;
  cellValue: string;
}

export type TStringOperator = 'and' | 'or';

export interface IAdvanceSearchSocialMedia {
  includeKeywords?: Array<string>;
  excludeKeywords?: Array<string>;
  fromUsername?: string;
  sentiment?: Array<TSentiment>;
  postOwnership?: Array<TPostOwnership>;
  contentTypeList?: Array<TContentType>;
  validationList?: Array<TValidation>;
  labelList?: Array<string>;
  excludeLabelList?: Array<string>;
  replyToOriginalId?: Array<string>;
  showEmptyLabel?: boolean;
  sentimentConfidenceLevelMin?: number;
  sentimentConfidenceLevelMax?: number;
  streamsCommentingToPost?: IRawPostmadeOutputWithPagination;
  selectedOriginalToPost?: Array<IRawPostmadeOutputEntity>;
  searchOriginalToPost?: string;
  excludeRt?: boolean;
  queryStringBooleanOperator: TStringOperator;
  languageList?: string[];
  userRating?: Array<number>;
}

export interface IAnalyticTotalGrowth {
  contentType: string;
  growth: number;
  pastValue: number;
  recentValue: number;
}

export interface IValueChronological {
  timestamp: Date;
  value: number;
}

export interface IChronological {
  name: string;
  values: Array<IValueChronological>;
}

export interface IChronologicalOutput {
  contentType: string;
  values: Array<IValueChronological>;
}

export interface ISeriesLineChart {
  name: string;
  data: Array<[Date, number]>;
}

export interface IGranularity {
  unit: 'hour' | 'day' | 'week' | 'month';
  value: number;
}

export interface ITableAtribute {
  page: number;
  sortingMethod: string;
  columnName: string;
  limit: number;
}

export interface INumericOutputWithGrowth {
  recentValue: any;
  growth: number | null;
  pastValue: number | any;
  contentType: string;
}

export interface IValuesPeakTime {
  name: string;
  data: Array<number>;
}

export interface IPeakTime {
  max: number;
  min: number;
  data: Array<IValuesPeakTime>;
}

export interface IPeakTimeOutput {
  max: number;
  min: number;
  peakTimeValue: Array<IValuesPeakTime>;
}

export interface IDataWorldCloud {
  data: Array<any>;
  max: number;
  min: number;
}

export interface IValuePieChart {
  name: string;
  value: number;
}

export interface Benchmark {
  baseline: number;
  value: number;
  benchmark: string;
}

export interface IRawPostmadeOutputEntity {
  originalId: string;
  socialMedia: TSocialMedia;
  displayPicture: string;
  link: string;
  content: string;
  fromId: string;
  fromName: string;
  timestamp: string;
  shareCount: number;
  likeCount: number;
  commentCount: number;
  viewCount: number;
  reach: number;
  contentType?: string;
  impression: number;
  engagementRate: number;
  engagement: number;
  benchmark: Benchmark;
  mediaLink: string;
}

export interface IRawPostmadeOutputWithPagination {
  result: Array<IRawPostmadeOutputEntity>;
  totalRows: number;
  totalPages: number;
  page: number;
  limit: number;
}

export interface IRawStreamOutputEntity {
  originalId: string;
  socialMedia: TSocialMedia;
  displayPicture: string;
  fromId: string;
  fromName: string;
  timestamp: Date;
  share: number;
  like: number;
  comment: number;
  audience: number;
  engagementRate: number;
  sentiment: TSentiment;
  objectModel: IObject;
  postOwnership: string;
  content: string;
  link: string;
  reach: number;
  impression: number;
  label: Array<ILabel>;
  isEdited: boolean;
  streamVisibility: TVisibility;
  replyToContent: any;
  contentType: TContentType;
}

export interface IUnlimitedScroll {
  next: string;
  hasNext: boolean;
}

export interface TotalSentiment {
  positive: any;
  negative: any;
  neutral: any;
}

export interface IRawStreamOutputWithUnlimitedScroll {
  result: Array<IRawStreamOutputEntity>;
  pagination: IUnlimitedScroll;
  total?: number;
  totalBySentiment: TotalSentiment;
}

export interface IDateRange {
  start: Date | string;
  end: Date | string;
}

export interface IDateRangeFormatted {
  start: Date | string;
  end: Date | string;
}

export interface IOptionDateRange {
  id: string;
  label: string;
  value: number;
  start?: Date;
  end?: Date;
}

export interface IAnalyticArguments {
  dateRange: IDateRange;
  dateRangeFormatted: IDateRangeFormatted;
  objectIdList: Array<string>;
  dateRangeOptionActive: IOptionDateRange;
  maxCountNode?: string | number;
}

export type TLocationType = 'City' | 'Region' | 'State';

export interface ILocation {
  id: string;
  parentId: string;
  name: string;
  displayName: string;
  level: TLocationType;
  createdAt: Date;
}

export interface ILocationResult {
  country: ILocation | undefined | null;
  state: ILocation | undefined | null;
  city: ILocation | undefined | null;
}

export type TOrder = 'asc' | 'desc';

export interface INumericOutput {
  name: string;
  value: number;
}

export type TTypeNotification = 'update' | 'download' | 'firstTime' | 'search';
export type TSubTypeNotification =
  | 'updateLabel'
  | 'updateSentiment'
  | 'ignoreStream'
  | 'downloadSocialMedia'
  | 'downloadOnlineMedia'
  | 'downloadOfflineMedia'
  | 'firstTimeSocialMediaData'
  | 'onDemandOnlineMediaSearch'
  | 'downloadCustomReport'
  | 'ignoreEngagedUser'
  | 'updateLocationEngagedUser'
  | 'updateLabelEngagedUser'
  | 'updateInterestEngagedUser'
  | 'updateGenderEngagedUser'
  | 'updateAgeRangeEngagedUser'
  | 'updateContentMediaType'
  | 'reClipping'
  | 'updateLabelOnm'
  | 'updateSentimentOnm'
  | 'downloadCustomDashboard'
  | 'updateVisibilityOnm'
  | 'downloadOnlineMediaClustering';

export interface INotification {
  id: string;
  title: string;
  typeNotification: TTypeNotification;
  subTypeNotification: TSubTypeNotification;
  status: string;
  currentProgress: number;
  totalProgress: number;
  createdAt: Date;
  detail: any;
}

export interface IAction {
  type: string;
  payload?: any;
}

export interface IPayloadSort<T> {
  columnName: keyof T;
  sortingMethod: 'asc' | 'desc';
}

export interface ITableWithPagination<T> {
  result: Array<T>;
  totalRows: number;
  totalPages: number;
  page: number;
  limit: number;
}

export interface ITablePaginationAttribute<T> {
  page: number;
  sortingMethod: string;
  columnName: keyof T;
  limit: number;
}

export interface IFilterAndSortTableAtribute {
  page: number;
  sortingMethod: string;
  columnName: string;
  limit: number;
}

export interface ILanguage {
  language: string;
  name: string;
}

export interface IWordCloud {
  name: string;
  value: number;
}

export interface IContentTypeTotal {
  image: number;
  text: number;
  video: number;
}

export interface IUserEngagement {
  comment: number;
  engagement: number;
  followers: number;
  fromName: string;
  like: number;
  share: number;
  socialMedia: TSocialMedia;
  talk: number;
  value: number;
  view: number;
  following: number;
  openness: string;
  conscientiousness: string;
  extraversion: string;
  agreeableness: string;
  neuroticism: string;
}

export interface IPostHogUserProps {
  email: string;
  userId: string;
  projectId: string;
  projectName: string;
}
export interface IClusteringConfig {
  desiredClusterCount: number;
  minimumLabelLength: number;
  stopWords: Array<string>;
}

export type TMediaNews = 'ofm' | 'onm';

interface CustomErrorReason {
  failures: string[];
}

interface CustomGraphQLErrorExtensions {
  reason: CustomErrorReason;
  [key: string]: unknown; // Add index signature
}

export interface CustomGraphQLError extends Omit<GraphQLError, 'extensions'> {
  extensions: CustomGraphQLErrorExtensions;
}
