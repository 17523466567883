import { useApolloClient } from '@apollo/react-hooks';
import { useRef } from 'react';
import { useAppDispatch } from 'src/app/hooks';
import {
  reducerUpdateContentAllMetricLoading,
  reducerUpdateContentNumericals,
  reducerUpdateContentOveralPeformance,
  reducerUpdateContentPeakTime,
  reducerUpdateContentPostmade,
  reducerUpdateContentPostmadeLoading,
  reducerUpdateContentPostType,
  reducerUpdateContentTalkWordCloud,
  reducerUpdateContentOveralPeformanceLoading,
  reducerUpdateContentAllMetricByDayLoading,
  reducerUpdateContentWordCloudLoading
} from 'src/features/sosmedContent';
import { IContentNumericOutputWithGrowth } from 'src/models/content';
import {
  IAdvanceSearchSocialMedia,
  IAnalyticTotalGrowth,
  IChronologicalOutput,
  IContentTypeTotal,
  INumericOutputWithGrowth,
  IPeakTimeOutput,
  IRawPostmadeOutputWithPagination,
  IWordCloud
} from 'src/models/general';
import { buildDataLineGroup } from 'src/utils/buildDataLineGroup';
import sortingDayPeakTime from 'src/utils/sortingDayPeakTime';
import {
  GET_CONTENT_GROUP_CONTENT_CHRONOLOGICAL,
  GET_CONTENT_PEAKTIME,
  GET_CONTENT_POSTMADE_WORD_CLOUD,
  GET_CONTENT_TALK_WORD_CLOUD,
  GET_CONTENT_TOP_POSTMADE_PAGINATION,
  GET_CONTENT_TOTAL_GROUP_CONTENT,
  GET_CONTENT_TYPE_TOTAL
} from './graphql/content/query';
import uuidNameSpace from 'uuid/v5';
import getNS from 'uuid/v4';
const RequestNameSpace = getNS();

interface IGetNumericalsResponse {
  content_getGroupedTotal: Array<IAnalyticTotalGrowth>;
}
interface IGetChronologicalContentResponse {
  content_getGroupedChronological: Array<IChronologicalOutput>;
}

interface IGetPeakTimesResponse {
  content_getPeakTime: IPeakTimeOutput;
}
interface IWordCloudResponse {
  content_getPostMadeWordCloud?: Array<IWordCloud>;
  content_getTalkWordCloud?: Array<IWordCloud>;
}

interface IContentTypeTotalResponse {
  content_getContentTypeTotal: IContentTypeTotal;
}

interface IRawPostmadeOutputWithPaginationResponse {
  content_getTopPostMadeWithPaginationV2: IRawPostmadeOutputWithPagination;
}

const initNumericalOutput: INumericOutputWithGrowth = {
  contentType: '',
  growth: null,
  pastValue: null,
  recentValue: null
};

const initNumericals: IContentNumericOutputWithGrowth = {
  postMade: initNumericalOutput,
  like: initNumericalOutput,
  comment: initNumericalOutput,
  engagementRate: initNumericalOutput
};

const useContent = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  const lastPromiseNumericals: any = useRef();
  const lastPromiseChronologicalContent: any = useRef();
  const lastPromisePeakTimes: any = useRef();
  const lastPromiseWordCloud: any = useRef();
  const lastPromiseContentTypeTotal: any = useRef();
  const lastPromiseRawPostmadeOutputWithPagination: any = useRef();

  const fetchNumericals = function (parameter: IAdvanceSearchSocialMedia) {
    const response = client
      .query<IGetNumericalsResponse>({
        query: GET_CONTENT_TOTAL_GROUP_CONTENT,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONTENT_TOTAL_GROUP_CONTENT',
            RequestNameSpace
          )
        }
      })
      .then((resTotalGroupContent) => {
        const dataGroupNumerical =
          resTotalGroupContent.data.content_getGroupedTotal;
        let mappingNumerical: IContentNumericOutputWithGrowth = initNumericals;
        dataGroupNumerical.forEach((numerical) => {
          mappingNumerical = {
            ...mappingNumerical,
            [numerical.contentType]: numerical
          };
        });
        return { ...resTotalGroupContent, data: mappingNumerical };
      });
    return response;
  };

  const getNumericals = async function (payload: IAdvanceSearchSocialMedia) {
    const response = fetchNumericals(payload);
    lastPromiseNumericals.current = response;
    if (response === lastPromiseNumericals.current) {
      response.then((response) => {
        dispatch(reducerUpdateContentNumericals(response));
      });
    }
  };

  const fetchChronologicalContent = function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const resGroupChronologicalContent = client
      .query<IGetChronologicalContentResponse>({
        query: GET_CONTENT_GROUP_CONTENT_CHRONOLOGICAL,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONTENT_GROUP_CONTENT_CHRONOLOGICAL',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        const generateDataLine = buildDataLineGroup(
          response.data.content_getGroupedChronological
        );
        return { ...response, data: generateDataLine };
      });
    return resGroupChronologicalContent;
  };

  const getContentOveralPeformance = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    dispatch(reducerUpdateContentOveralPeformanceLoading(true));
    const response = fetchChronologicalContent(payload);
    lastPromiseChronologicalContent.current = response;
    if (response === lastPromiseChronologicalContent.current) {
      response.then((response) => {
        dispatch(reducerUpdateContentOveralPeformance(response));
      });
    }
  };

  const fetchWordCloud = function (
    parameter: IAdvanceSearchSocialMedia,
    type: 'comment' | 'postmade'
  ) {
    const resWordCloud = client
      .query<IWordCloudResponse>({
        query:
          type === 'comment'
            ? GET_CONTENT_TALK_WORD_CLOUD
            : GET_CONTENT_POSTMADE_WORD_CLOUD,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            type === 'comment'
              ? 'GET_CONTENT_TALK_WORD_CLOUD'
              : 'GET_CONTENT_POSTMADE_WORD_CLOUD',
            RequestNameSpace
          )
        }
      })
      .then((resWordCloud) => {
        const resData =
          type === 'comment'
            ? resWordCloud.data.content_getTalkWordCloud
            : resWordCloud.data.content_getPostMadeWordCloud;
        const wordClouds = resData.map((word) => ({
          text: word.name,
          value: word.value
        }));
        const data =
          wordClouds.length > 0 ? wordClouds.map((word) => word.value) : [];
        const max = data.length > 0 ? Math.max(...data) : 0;
        const min = data.length > 0 ? Math.min(...data) : 1;
        return { ...resWordCloud, data: { data: wordClouds, max, min } };
      });
    return resWordCloud;
  };

  const getWordCloud = async function (
    payload: IAdvanceSearchSocialMedia,
    type: 'comment' | 'postmade'
  ) {
    dispatch(reducerUpdateContentWordCloudLoading(true));
    const response = fetchWordCloud(payload, type);
    lastPromiseWordCloud.current = response;
    if (response === lastPromiseWordCloud.current) {
      response.then((response) => {
        dispatch(reducerUpdateContentTalkWordCloud(response));
      });
    }
  };

  const fetchContentPostType = function (parameter: IAdvanceSearchSocialMedia) {
    const response = client
      .query<IContentTypeTotalResponse>({
        query: GET_CONTENT_TYPE_TOTAL,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONTENT_TYPE_TOTAL',
            RequestNameSpace
          )
        }
      })
      .then((resContentTypeTotal) => {
        const { text, image, video } =
          resContentTypeTotal.data.content_getContentTypeTotal;
        const pieFormat = [
          {
            name: 'text',
            value: text
          },
          {
            name: 'image',
            value: image
          },
          {
            name: 'video',
            value: video
          }
        ];
        return { ...resContentTypeTotal, data: pieFormat };
      });
    return response;
  };

  const getContentTypeTotal = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    const response = fetchContentPostType(payload);
    lastPromiseContentTypeTotal.current = response;
    if (response === lastPromiseContentTypeTotal.current) {
      response.then((response) => {
        dispatch(reducerUpdateContentPostType(response));
      });
    }
  };

  const fetchContentPostMade = function (parameter: IAdvanceSearchSocialMedia) {
    const response = client
      .query<IRawPostmadeOutputWithPaginationResponse>({
        query: GET_CONTENT_TOP_POSTMADE_PAGINATION,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONTENT_TOP_POSTMADE_PAGINATION',
            RequestNameSpace
          )
        }
      })
      .then((resTopPostmade) => {
        return {
          ...resTopPostmade,
          data: resTopPostmade.data.content_getTopPostMadeWithPaginationV2
        };
      });
    return response;
  };

  const getContentPostMade = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    dispatch(reducerUpdateContentPostmadeLoading(true));
    const response = fetchContentPostMade(payload);
    lastPromiseRawPostmadeOutputWithPagination.current = response;
    if (response === lastPromiseRawPostmadeOutputWithPagination.current) {
      response.then((rest) => {
        dispatch(reducerUpdateContentPostmade(rest));
      });
    }
  };

  const fetchPeakTimes = async function (parameter: IAdvanceSearchSocialMedia) {
    const resPeakTimes = await client.query<IGetPeakTimesResponse>({
      query: GET_CONTENT_PEAKTIME,
      variables: parameter
    });
    const { max, min, peakTimeValue } = resPeakTimes.data.content_getPeakTime;
    const data = peakTimeValue ? sortingDayPeakTime(peakTimeValue) : [];
    return { ...resPeakTimes, data: { data, max, min } };
  };

  const getPeakTimes = async function (payload: any) {
    if (payload.objectList < 1) return;
    dispatch(reducerUpdateContentPostmadeLoading(true));
    const response = fetchPeakTimes(payload);
    lastPromisePeakTimes.current = response;
    if (response === lastPromisePeakTimes.current) {
      response.then((rest) => {
        dispatch(reducerUpdateContentPeakTime(rest));
      });
    }
  };

  const getContentAllMetric = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    dispatch(reducerUpdateContentAllMetricLoading(true));
    getNumericals(payload);
    getContentOveralPeformance(payload);
    getContentPostMade(payload);
    getContentTypeTotal(payload);
    getPeakTimes(payload);
    getWordCloud(payload, 'comment');
  };

  const getContentAllMetricByDay = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    dispatch(reducerUpdateContentAllMetricByDayLoading(true));
    getContentPostMade(payload);
    getContentTypeTotal(payload);
    getPeakTimes(payload);
    getWordCloud(payload, 'comment');
  };

  return {
    getContentAllMetric,
    getContentPostMade,
    getContentOveralPeformance,
    getContentAllMetricByDay,
    getNumericals,
    getContentTypeTotal,
    getPeakTimes,
    getWordCloud
  };
};

export default useContent;
